import { Box, Stack, Title } from '@mantine/core';
import { CompanySettingsForm } from '../SettingsForms/CompanySettingsForm';
import { SettingsDomains } from '../SettingsDomains/SettingsDomains';
import { useUserType } from '@/hooks/use-user-type';
import { UserTypeEnum } from '@/openapi/requests';

const SubTitle = ({ title }: { title: string }) => (
  <Title order={2} textfor="h5" mb="md">
    {title}
  </Title>
);

export function CompanySettingsTab() {
  const userType = useUserType();
  const isTriager = userType === UserTypeEnum.TRIAGER;

  return (
    <Stack>
      <Box>
        <SubTitle title="Company Settings" />
        <CompanySettingsForm />
        {isTriager && <SettingsDomains />}
      </Box>
    </Stack>
  );
}
