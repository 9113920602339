import { Link } from 'react-router-dom';
import {
  Anchor,
  Card,
  Divider,
  Group,
  rem,
  Stack,
  Text,
  ThemeIcon,
  Title,
  Box,
  Grid,
} from '@mantine/core';
import { IconMessageDots, IconPaperclip } from '@tabler/icons-react';
import SeverityStatus from '@/components/Common/SeverityStatus/SeverityStatus';
import RiskStatusSelect from '@/components/Risks/RiskStatusSelect/RiskStatusSelect';
import { CopyButton } from '@/components/Core/CopyButton/CopyButton';
import { RiskCategoryBadge } from '@/components/Risks/RiskCategoryBadge/RiskCategoryBadge';
import { RiskEnvironmentsBadge } from '@/components/Risks/RiskEnvironmentBadge/RiskEnvironmentBadge';
import { RiskDto, UserTypeEnum } from '@/openapi/requests';
import DateDisplay from '@/components/Core/DateDisplay/DateDisplay';
import RiskSLA from '@/components/Risks/RiskSLA/RiskSLA';
import If from '@/components/Common/If/If';
import { RiskTagsMenu } from '@/components/Risks/RiskTags/RiskTagsMenu';
import { RiskTagsView } from '@/components/Risks/RiskTags/RiskTagsView';
import { useUserType } from '@/hooks/use-user-type';
import RiskAssign from '../RiskAssign/RiskAssign';

const RiskDate = ({ date }: { date: string }) => (
  <Text textfor="info">
    <DateDisplay showDate date={date} />
  </Text>
);

const RiskTitle = ({ id, title }: { id: string; title: string }) => (
  <Group gap={rem(4)} wrap="nowrap">
    <Anchor underline="hover" component={Link} to={`/risk/${id}`}>
      <Title order={3} textfor="h6" lineClamp={1} style={{ maxWidth: '650px' }}>
        {title}
      </Title>
    </Anchor>
    <CopyButton value={title} />
  </Group>
);

const CommentCount = ({ count = 0 }: { count: number }) => (
  <Group>
    <ThemeIcon size="xs">
      <IconMessageDots size="100%" />
    </ThemeIcon>
    <Text span textfor="info">
      {count}
    </Text>
  </Group>
);

const AttachmentCount = ({ count = 0 }: { count: number }) => (
  <Group>
    <ThemeIcon size="xs">
      <IconPaperclip size="100%" />
    </ThemeIcon>
    <Text span textfor="info">
      {count}
    </Text>
  </Group>
);

const CardRowLayout = ({ risk, projectId }: { risk: RiskDto; projectId: string }) => {
  const userType = useUserType();

  return (
    <Card shadow="xs">
      <Group justify="space-between" wrap="nowrap">
        <Stack gap={rem(4)}>
          <SeverityStatus severity={risk.severity} />
          <RiskTitle id={risk.id} title={risk.title} />
          <If condition={userType === UserTypeEnum.CLIENT}>
            <Box w="300px">
              <RiskSLA createdDate={risk.created} closedDate={risk.closed} />
            </Box>
          </If>
        </Stack>
        <Group justify="space-between">
          <RiskStatusSelect status={risk.status} riskId={risk.id} />
        </Group>
      </Group>
      <Divider mt="md" mb="xs" />
      <Group justify="space-between">
        <Group gap="sm" wrap="wrap">
          <RiskCategoryBadge category={risk.category} />
          <RiskEnvironmentsBadge environment={risk.environment} />
          <Divider orientation="vertical" />
          <CommentCount count={risk.comment_count} />
          <AttachmentCount count={risk.attachments.length} />
          <Divider orientation="vertical" />
          {userType !== UserTypeEnum.RESEARCHER && (
            <Group>
              <RiskTagsView riskId={risk.id} />
              <RiskTagsMenu riskId={risk.id} />
            </Group>
          )}
        </Group>
        <Group gap="sm" wrap="wrap">
          <Group gap="xs">
            <RiskDate date={risk.created} />
            {risk.closed && (
              <>
                <Text textfor="info">-</Text>
                <RiskDate date={risk.closed} />
              </>
            )}
          </Group>
          <If condition={userType === UserTypeEnum.CLIENT}>
            <Divider orientation="vertical" />
            <RiskAssign risk={risk} projectId={projectId} />
          </If>
        </Group>
      </Group>
    </Card>
  );
};

const CardColumnLayout = ({ risk, projectId }: { risk: RiskDto; projectId: string }) => {
  const userType = useUserType();

  return (
    <Card w={rem(400)} shadow="xs">
      <Stack gap="sm">
        <Group justify="space-between">
          <SeverityStatus severity={risk.severity} />
          <If condition={userType === UserTypeEnum.CLIENT}>
            <RiskAssign risk={risk} projectId={projectId} />
          </If>
        </Group>
        <Group justify="space-between" align="start" wrap="nowrap">
          <Box flex={1}>
            <RiskTitle id={risk.id} title={risk.title} />
          </Box>
        </Group>
        <Group justify="space-between">
          <Group justify="left">
            <RiskCategoryBadge category={risk.category} />
            <RiskEnvironmentsBadge environment={risk.environment} />
            <Divider orientation="vertical" />
            {userType !== UserTypeEnum.RESEARCHER && (
              <Group>
                <RiskTagsView riskId={risk.id} />
                <RiskTagsMenu riskId={risk.id} />
              </Group>
            )}
          </Group>
        </Group>
        <Divider />
        <Box style={{ overflow: 'hidden' }}>
          <Grid columns={4}>
            <Grid.Col span={2}>
              <Group>
                <CommentCount count={risk.comment_count} />
                <AttachmentCount count={risk.attachments.length} />
              </Group>
            </Grid.Col>
            <Grid.Col span={2}>
              <Group justify="end" gap="xs">
                <RiskDate date={risk.created} />
                {risk.closed && (
                  <>
                    <Text textfor="info">-</Text>
                    <RiskDate date={risk.closed} />
                  </>
                )}
              </Group>
            </Grid.Col>
          </Grid>
        </Box>
      </Stack>
    </Card>
  );
};

const RiskCard = ({
  layout = 'Row',
  risk,
  projectId,
}: {
  layout: 'Row' | 'Column';
  risk: RiskDto;
  projectId: string;
}) =>
  layout === 'Row' ? (
    <CardRowLayout risk={risk} projectId={projectId} />
  ) : (
    <CardColumnLayout risk={risk} projectId={projectId} />
  );

export default RiskCard;
