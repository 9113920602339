export const getElementCenterPosPercent = (elm: HTMLElement | null) => {
  if (!elm) return { x: 50, y: 50 };

  const rect = elm.getBoundingClientRect();

  return {
    x: ((rect.left + rect.width / 2) / window.innerWidth) * 100,
    y: ((rect.top + rect.height / 2) / window.innerHeight) * 100,
  };
};

export const handleFileDownload = (signedLink: string, _fileName: string) => {
  const processedUrl = signedLink.replace('http://localstack:4566', 'http://localhost:4566');
  window.open(processedUrl, '_blank');
};
