import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Combobox, Divider, Group, InputBase, ThemeIcon, useCombobox } from '@mantine/core';
import {
  IconBolt,
  IconCircleCheck,
  IconProgress,
  IconCircleXFilled,
  IconArrowNarrowRight,
  IconChevronDown,
  IconExchangeOff,
} from '@tabler/icons-react';
import { RiskStatusBadge } from '@/components/Risks/RiskStatusBadge/RiskStatusBadge';

import { RiskPartialUpdateDto, RiskStatusEnum } from '@/openapi/requests';

import classes from './RiskStatusSelect.module.css';
import { confettiStore } from '@/store/confetti-store';
import {
  useRiskAvailableStatuses,
  useRiskAvailableStatusesKey,
  useRiskPartialUpdate,
} from '@/openapi/queries';
import { useQueryClient } from '@tanstack/react-query';

interface StatusOptionProps {
  label: string;
  description: string;
  value: RiskStatusEnum;
  className: string;
  icon: JSX.Element;
}
interface partialUpdateRiskProps {
  riskId: string;
  payload: RiskPartialUpdateDto;
}
const statusOptions: Record<RiskStatusEnum, StatusOptionProps> = {
  DRAFT: {
    label: 'Draft',
    description: 'Mark as Draft',
    value: RiskStatusEnum.DRAFT,
    className: 'draft',
    icon: (
      <ThemeIcon className={classes.statusIcon}>
        <IconBolt size={16} />
      </ThemeIcon>
    ),
  },
  REVIEW: {
    label: 'Review',
    description: 'Mark as Review',
    value: RiskStatusEnum.REVIEW,
    className: 'review',
    icon: (
      <ThemeIcon className={classes.statusIcon}>
        <IconBolt size={16} />
      </ThemeIcon>
    ),
  },
  NEW: {
    label: 'New',
    description: 'Mark as New',
    value: RiskStatusEnum.NEW,
    className: 'new',
    icon: (
      <ThemeIcon className={classes.statusIcon}>
        <IconBolt size={16} />
      </ThemeIcon>
    ),
  },
  IN_PROGRESS: {
    label: 'In Progress',
    description: 'Move to In Progress',
    value: RiskStatusEnum.IN_PROGRESS,
    className: 'in_progress',
    icon: (
      <ThemeIcon className={classes.statusIcon}>
        <IconProgress size={16} />
      </ThemeIcon>
    ),
  },
  CLOSED: {
    label: 'Closed',
    description: 'Mark as Fixed',
    value: RiskStatusEnum.CLOSED,
    className: 'closed',
    icon: (
      <ThemeIcon className={classes.statusIcon}>
        <IconCircleCheck size={16} />
      </ThemeIcon>
    ),
  },
  REJECTED: {
    label: 'Rejected',
    description: 'Mark as Rejected',
    value: RiskStatusEnum.REJECTED,
    className: 'rejected',
    icon: (
      <ThemeIcon className={classes.statusIcon}>
        <IconCircleXFilled size={16} />
      </ThemeIcon>
    ),
  },
  DUPLICATE: {
    label: 'Duplicate',
    description: 'Mark as Duplicate',
    value: RiskStatusEnum.DUPLICATE,
    className: 'duplicate',
    icon: (
      <ThemeIcon className={classes.statusIcon}>
        <IconBolt size={16} />
      </ThemeIcon>
    ),
  },
  ACCEPTED: {
    label: 'Risk Accepted',
    description: 'Mark as Will Not Fix',
    value: RiskStatusEnum.ACCEPTED,
    className: 'accepted',
    icon: (
      <ThemeIcon className={classes.statusIcon}>
        <IconExchangeOff size={16} />
      </ThemeIcon>
    ),
  },
} as const;

const StatusOption = ({ status, description }: { status: RiskStatusEnum; description: string }) => (
  <Group justify="space-between">
    {description}
    <ThemeIcon>
      <IconArrowNarrowRight />
    </ThemeIcon>
    <RiskStatusBadge size="md" status={status} isRisk={true} />
  </Group>
);

const RiskStatusSelect = ({
  riskId,
  status,
}: {
  riskId: partialUpdateRiskProps['riskId'];
  status: RiskStatusEnum;
}) => {
  const queryClient = useQueryClient();
  const {
    data: availableStatuses,
    isLoading,
    isError,
  } = useRiskAvailableStatuses({ path: { riskId } });

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
    },
  });

  const dropdownTriggerRef = useRef<HTMLButtonElement>(null);

  const [statusOption, setStatusOption] = useState<StatusOptionProps>(statusOptions[status]);
  const statuses: StatusOptionProps[] =
    availableStatuses?.statuses.map(
      (_status) => statusOptions[_status as unknown as RiskStatusEnum]
    ) ?? [];
  const { REJECTED } = statusOptions;
  const { toggleShoot } = confettiStore();

  const updateRiskMutation = useRiskPartialUpdate(undefined, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [useRiskAvailableStatusesKey] });
      if (data.data?.status === statusOptions.CLOSED.value) {
        toggleShoot(true);
      }
    },
  });

  const updateRisk = (id: string, newStatus: RiskStatusEnum) => {
    updateRiskMutation.mutate({
      path: { riskId: id },
      body: {
        status: newStatus,
      },
    });
  };

  const handleOptionSubmit = (val: string) => {
    const selectStatus = val as RiskStatusEnum;
    if (selectStatus === statusOption.value) {
      combobox.closeDropdown();
      return;
    }

    setStatusOption(statusOptions[selectStatus]);
    updateRisk(riskId, selectStatus);
    combobox.closeDropdown();
  };

  useEffect(() => {
    setStatusOption(statusOptions[status]);
  }, [status]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return null;
  }

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={handleOptionSubmit}
      classNames={{
        dropdown: classes.Dropdown,
      }}
      disabled={statuses.length === 0}
    >
      <Combobox.Target>
        <InputBase
          ref={dropdownTriggerRef}
          component="button"
          type="button"
          onClick={() => {
            combobox.toggleDropdown();
          }}
          pointer
          rightSection={statuses.length > 0 && <IconChevronDown stroke={1.5} size="16px" />}
          rightSectionPointerEvents="none"
          className={clsx(classes[statusOption.className])}
          classNames={{
            input: classes.input,
            section: classes.icon,
          }}
          leftSection={statusOption.icon}
        >
          {statusOption.label}
        </InputBase>
      </Combobox.Target>

      {statuses.length > 0 && (
        <Combobox.Dropdown>
          <Combobox.Options>
            {statuses
              .filter(({ value }) => value !== RiskStatusEnum.REJECTED)
              .map(({ value, description, className }) => (
                <Combobox.Option key={value} value={value} className={classes[className]}>
                  <StatusOption status={value} description={description} />
                </Combobox.Option>
              ))}

            {statuses.find(({ value }) => value === RiskStatusEnum.REJECTED) && (
              <>
                <Divider my="xs" />

                <Combobox.Option value="REJECTED" className={classes[REJECTED.className]}>
                  <StatusOption status={REJECTED.value} description={REJECTED.description} />
                </Combobox.Option>
              </>
            )}
          </Combobox.Options>
        </Combobox.Dropdown>
      )}
    </Combobox>
  );
};

export default RiskStatusSelect;
