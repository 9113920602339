import { Box, Container, rem, Tabs } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { VerticalTabs } from '@/components/Core/VerticalTabs/VerticalTabs';
import { CompanySettingsTab } from '@/components/Settings/SettingsTabs/CompanySettingsTab';
import { ManageUsersTab } from '@/components/Settings/SettingsTabs/ManageUsersTab';
import { MySettingsTab } from '@/components/Settings/SettingsTabs/MySettingsTab';
import { DesktopTabSelect, MobileTabSelect } from '@/components/Settings/SettingsTabs/TabSelect';
import { CompanyContextProvider } from '@/contexts/CompanyContext';
import { useTrackPageView } from '@/hooks/use-track-page-view';
import { useEffect, useState } from 'react';

export function SettingsPage() {
  const navigate = useNavigate();
  const params = useParams();
  const { tabValue = 'my-settings', companyId = '' } = params;
  const [activeTab, setActiveTab] = useState('');

  const isMobileSize = useMediaQuery('(max-width: 48em)');

  useTrackPageView('settings');

  useEffect(() => {
    setActiveTab(companyId === '' ? tabValue : `${tabValue}/${companyId}`);
  }, [companyId, tabValue]);

  return (
    <Container>
      <VerticalTabs
        value={activeTab}
        onChange={(value) => {
          navigate(`/settings/${value}`);
          setActiveTab(value!);
        }}
      >
        {!isMobileSize && <DesktopTabSelect />}

        <Box
          p={{
            base: 'xs',
            sm: rem(48),
          }}
          w="100%"
        >
          {isMobileSize && <MobileTabSelect tabValue={tabValue} />}

          <Tabs.Panel value="my-settings">
            <MySettingsTab />
          </Tabs.Panel>

          {companyId && (
            <CompanyContextProvider key={`company-context-${companyId}`} companyId={companyId}>
              <Tabs.Panel value={`company-settings/${companyId}`}>
                <CompanySettingsTab />
              </Tabs.Panel>

              <Tabs.Panel value={`manage-users/${companyId}`}>
                <ManageUsersTab />
              </Tabs.Panel>
            </CompanyContextProvider>
          )}
        </Box>
      </VerticalTabs>
    </Container>
  );
}
