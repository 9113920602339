import 'mantine-datatable/styles.layer.css';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Notifications } from '@mantine/notifications';

import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import AnalyticsProvider from '@/Providers/AnalyticsProvider';
import { Router } from '@/Router';

import { theme } from '@/theme';
import Confetti from './components/Common/Confetti';
import './fetchClient';

const queryClient = new QueryClient();

export default function App() {
  return (
    <MantineProvider defaultColorScheme="light" theme={theme}>
      <ModalsProvider>
        <QueryClientProvider client={queryClient}>
          <Notifications />
          <Router queryClient={queryClient} />
          <Confetti />
          <AnalyticsProvider />
        </QueryClientProvider>
      </ModalsProvider>
    </MantineProvider>
  );
}
