import { useAssetDiscoveryRunList, useDomainRetrieve } from '@/openapi/queries';
import { Button, Group, Tabs, Title } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { useState } from 'react';
import { PAGE_SIZES } from '@/utils/constants';
import { AssetDiscoveryRunStatusEnum } from '@/openapi/requests';
import AssetStatusBadge from '@/components/Assets/AssetStatusBadge/AssetStatusBadge';
import { IconArrowLeft } from '@tabler/icons-react';

export const DomainAssets = ({
  companyId,
  domainId,
  setShowDomainAssets,
  setDomainId,
}: {
  companyId: string;
  domainId: string;
  setShowDomainAssets: (show: boolean) => void;
  setDomainId: (id: string) => void;
}) => {
  const [assetsPage, setAssetsPage] = useState(1);
  const [assetsPageSize, setAssetsPageSize] = useState(PAGE_SIZES[2]);
  const [scanHistoryPage, setScanHistoryPage] = useState(1);
  const [scanHistoryPageSize, setScanHistoryPageSize] = useState(PAGE_SIZES[2]);
  const [assetsSortBy, setAssetsSortBy] = useState('domain');
  const [assetsReverseSortDirection, setAssetsReverseSortDirection] = useState(false);
  const [scanHistorySortBy, setScanHistorySortBy] = useState('createdAt');
  const [scanHistoryReverseSortDirection, setScanHistoryReverseSortDirection] = useState(false);
  const { data, isLoading: isAssetsLoading } = useDomainRetrieve(
    {
      path: {
        companyId: companyId!,
        domainId: domainId!,
      },
      query: {
        page: assetsPage,
        pageSize: assetsPageSize,
        sortBy: assetsSortBy,
        sortOrder: assetsReverseSortDirection ? 'desc' : 'asc',
      },
    },
    [assetsPage, assetsPageSize, assetsSortBy, assetsReverseSortDirection],
    {
      enabled: !!domainId && !!companyId,
    }
  );

  const { data: history, isLoading: isHistoryLoading } = useAssetDiscoveryRunList(
    {
      query: {
        companyId: companyId!,
        domainId: domainId!,
        page: scanHistoryPage,
        pageSize: scanHistoryPageSize,
        sortBy: scanHistorySortBy,
        sortOrder: scanHistoryReverseSortDirection ? 'desc' : 'asc',
      },
    },
    [scanHistoryPage, scanHistoryPageSize, scanHistorySortBy, scanHistoryReverseSortDirection],
    {
      enabled: !!domainId && !!companyId,
    }
  );

  const handleAssetsSort = (field: string) => {
    const isSameField = assetsSortBy === field;
    setAssetsReverseSortDirection(isSameField ? !assetsReverseSortDirection : false);
    setAssetsSortBy(field);
  };

  const handleScanHistorySort = (field: string) => {
    const isSameField = scanHistorySortBy === field;
    setScanHistoryReverseSortDirection(isSameField ? !scanHistoryReverseSortDirection : false);
    setScanHistorySortBy(field);
  };

  return (
    <>
      <Group mb="sm" mt={20}>
        <Button
          variant="default"
          leftSection={<IconArrowLeft size={14} />}
          onClick={() => {
            setShowDomainAssets(false);
            setDomainId('');
          }}
        >
          Back
        </Button>
      </Group>
      <Group align="center" my="md">
        <Title order={3} textfor="h2">
          {data?.domain}
        </Title>
      </Group>
      <Tabs defaultValue="assets">
        <Tabs.List>
          <Tabs.Tab value="assets">Assets</Tabs.Tab>
          <Tabs.Tab value="scan-history">Scan History</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="assets" pt="md">
          <DataTable
            withTableBorder
            borderRadius="sm"
            withColumnBorders
            striped
            highlightOnHover
            fetching={isAssetsLoading}
            columns={[
              {
                accessor: 'name',
                title: 'Name',
                sortable: true,
                render: (row) => row.name,
              },
              {
                accessor: 'createdAt',
                title: 'Creation Date',
                render: (row) => new Date(row.createdAt).toLocaleDateString(),
                sortable: true,
              },
              {
                accessor: 'updatedAt',
                title: 'Last Update',
                render: (row) => new Date(row.updatedAt).toLocaleDateString(),
                sortable: true,
              },
              {
                accessor: 'lastScan',
                title: 'Last Scan',
                render: (row) =>
                  row.lastScan ? new Date(row.lastScan).toLocaleDateString() : 'N/A',
                sortable: true,
              },
              {
                accessor: 'ips',
                title: 'IPs',
                render: (row) => row.ips,
                sortable: false,
              },
            ]}
            records={data?.assets || []}
            totalRecords={data?.assets?.length || 0}
            recordsPerPage={assetsPageSize}
            page={assetsPage}
            onPageChange={setAssetsPage}
            sortStatus={{
              columnAccessor: assetsSortBy,
              direction: assetsReverseSortDirection ? 'desc' : 'asc',
            }}
            onSortStatusChange={({ columnAccessor }) => handleAssetsSort(columnAccessor)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setAssetsPageSize}
            noRecordsText="No records to show"
          />
        </Tabs.Panel>
        <Tabs.Panel value="scan-history" pt="md">
          <DataTable
            withTableBorder
            borderRadius="sm"
            withColumnBorders
            striped
            highlightOnHover
            fetching={isHistoryLoading}
            columns={[
              {
                accessor: 'domain',
                title: 'Domain',
                sortable: false,
              },
              {
                accessor: 'status',
                title: 'Status',
                sortable: true,
                render: (row) => (
                  <AssetStatusBadge status={row.status as AssetDiscoveryRunStatusEnum} />
                ),
              },
              {
                accessor: 'createdAt',
                title: 'Creation Date',
                render: (row) => new Date(row.createdAt).toLocaleDateString(),
                sortable: true,
              },
              {
                accessor: 'updatedAt',
                title: 'Last Update',
                render: (row) => new Date(row.updatedAt).toLocaleDateString(),
                sortable: true,
              },
            ]}
            records={history?.results || []}
            totalRecords={history?.totalPages || 0}
            recordsPerPage={scanHistoryPageSize}
            page={scanHistoryPage}
            onPageChange={setScanHistoryPage}
            sortStatus={{
              columnAccessor: scanHistorySortBy,
              direction: scanHistoryReverseSortDirection ? 'desc' : 'asc',
            }}
            onSortStatusChange={({ columnAccessor }) => handleScanHistorySort(columnAccessor)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setScanHistoryPageSize}
            noRecordsText="No records to show"
          />
        </Tabs.Panel>
      </Tabs>
      {/* </Card> */}
    </>
  );
};
