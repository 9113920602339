import { useCompany } from '@/hooks/use-company';
import {
  useDeleteDomain,
  useDomainList,
  useDomainListKey,
  useScheduleAssetDiscoveryRun,
} from '@/openapi/queries';
import { ActionIcon, Button, Group, Title, Tooltip, Text } from '@mantine/core';
import { IconListSearch, IconPlus, IconTrash } from '@tabler/icons-react';
import { DataTable } from 'mantine-datatable';
import { useState } from 'react';
import AddDomainModal from './AddDomainModal';
import { PAGE_SIZES } from '@/utils/constants';
import { useQueryClient } from '@tanstack/react-query';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { DomainAssets } from './DomainAssets';

export function SettingsDomains() {
  const [showDomainAssets, setShowDomainAssets] = useState(false);
  const [domainId, setDomainId] = useState('');
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZES[2]);
  const [sortBy, setSortBy] = useState('domain');
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [opened, setOpened] = useState(false);
  const company = useCompany();
  const mutation = useScheduleAssetDiscoveryRun(undefined, {
    onSuccess: () => {
      notifications.show({
        color: 'green',
        title: 'Success',
        message: 'Discovery run scheduled successfully',
      });
      queryClient.invalidateQueries({ queryKey: [useDomainListKey] });
    },
    onError: (error: Error) => {
      notifications.show({
        color: 'red',
        title: 'Failed to schedule discovery run',
        message: error.message,
      });
    },
  });

  const { data, isLoading } = useDomainList({
    body: {
      page,
      pageSize,
      sortBy,
      sortOrder: reverseSortDirection ? 'desc' : 'asc',
    },
    path: {
      companyId: company.id,
    },
  });

  const deleteDomain = useDeleteDomain(undefined, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [useDomainListKey] });
      notifications.show({
        color: 'green',
        title: 'Success',
        message: 'Domain deleted successfully',
      });
    },
    onError: (error: Error) => {
      notifications.show({
        color: 'red',
        title: 'Failed to delete domain',
        message: error.message,
      });
    },
  });

  const handleSort = (field: string) => {
    const isSameField = sortBy === field;
    setReverseSortDirection(isSameField ? !reverseSortDirection : false);
    setSortBy(field);
  };

  const handleDeleteDomain = async (domainId: string, domain: string) => {
    modals.openConfirmModal({
      title: 'Confirm domain deletion',
      centered: true,
      children: (
        <Text fz="sm">
          Are you sure you want to delete <b>{domain}</b> from domain list?
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: async () => {
        deleteDomain.mutate({
          path: {
            companyId: company.id,
            domainId,
          },
        });
      },
    });
  };

  const handleRunDiscovery = (domainId: string) => {
    modals.openConfirmModal({
      title: 'Confirm domain discovery run',
      centered: true,
      children: <Text fz="sm">Are you sure you want to run discovery on this domain?</Text>,
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: async () => {
        mutation.mutate({
          body: {
            companyId: company.id,
            domainId,
          },
          path: {
            companyId: company.id,
            domainId,
          },
        });
      },
    });
  };

  if (showDomainAssets && domainId) {
    return (
      <DomainAssets
        companyId={company.id}
        domainId={domainId}
        setShowDomainAssets={setShowDomainAssets}
        setDomainId={setDomainId}
      />
    );
  }

  return (
    <>
      <Group justify="space-between" align="center" mb="sm" mt={20}>
        <Title order={3} textfor="h2" mb="md">
          Domains
        </Title>
        <Button onClick={() => setOpened(true)} leftSection={<IconPlus size={14} />}>
          Add Domain
        </Button>
      </Group>
      <DataTable
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        fetching={isLoading}
        columns={[
          {
            accessor: 'domain',
            title: 'Domain',
            sortable: true,
            render: (row) => (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setDomainId(row.id);
                  setShowDomainAssets(true);
                }}
              >
                {row.domain}
              </div>
            ),
          },
          {
            accessor: 'createdAt',
            title: 'Creation Date',
            render: (row) => new Date(row.createdAt).toLocaleDateString(),
            sortable: true,
          },
          {
            accessor: 'lastScan',
            title: 'Last Scan',
            render: (row) => (row.lastScan ? new Date(row.lastScan).toLocaleDateString() : 'N/A'),
            sortable: true,
          },
          {
            accessor: 'actions',
            title: 'Actions',
            render: (row) => (
              <Group gap="sm">
                <Tooltip label="Remove domain">
                  <ActionIcon
                    variant="default"
                    onClick={() => handleDeleteDomain(row.id, row.domain)}
                  >
                    <IconTrash size={14} />
                  </ActionIcon>
                </Tooltip>
                <Tooltip label="Run assets discovery">
                  <ActionIcon variant="default" onClick={() => handleRunDiscovery(row.id)}>
                    <IconListSearch size={14} />
                  </ActionIcon>
                </Tooltip>
              </Group>
            ),
          },
        ]}
        records={data?.results || []}
        totalRecords={data?.totalCount || 0}
        recordsPerPage={pageSize}
        page={page}
        onPageChange={setPage}
        sortStatus={{
          columnAccessor: sortBy,
          direction: reverseSortDirection ? 'desc' : 'asc',
        }}
        onSortStatusChange={({ columnAccessor }) => handleSort(columnAccessor)}
        recordsPerPageOptions={PAGE_SIZES}
        onRecordsPerPageChange={setPageSize}
        noRecordsText="No records to show"
      />
      <AddDomainModal opened={opened} onClose={() => setOpened(false)} companyId={company.id} />
    </>
  );
}
