import React, { Fragment, useEffect } from 'react';
import moment from 'moment';
import { useDebouncedValue } from '@mantine/hooks';

import RiskCard from '@/components/Risks/RiskCard/RiskCard';

import { useRiskFilters } from '@/store/risk-store';

import { RiskListDto, RiskStatusEnum, type ProjectDto } from '@/openapi/requests';
import { debounceDuration } from '@/configuration';
import LoadingScreen from '@/components/Common/LoadingScreen/LoadingScreen';
import { useRiskSortingStore } from '@/store/risk-sorting-store';
import { useRiskListInfinite } from '@/openapi/queries/infiniteQueries';
import { useInView } from 'react-intersection-observer';
import { Center, Loader } from '@mantine/core';
interface RiskCardsProps {
  project: ProjectDto;
}

export const RiskCards = React.memo(({ project }: RiskCardsProps) => {
  const { ref, inView } = useInView({
    rootMargin: '200px 0px',
    threshold: 0,
  });
  const { categories, severities, dateRange, tags, status, hideRejected, assignee } =
    useRiskFilters();
  const { order } = useRiskSortingStore();

  const [debCategories] = useDebouncedValue(categories, debounceDuration);
  const [debSeverities] = useDebouncedValue(severities, debounceDuration);
  const [debDateRange] = useDebouncedValue(dateRange, debounceDuration);
  const [debTags] = useDebouncedValue(tags, debounceDuration);
  const [debStatuses] = useDebouncedValue(status, debounceDuration);

  const date_range_from = debDateRange[0] ? moment(debDateRange[0]).format('L') : undefined;
  const date_range_to = debDateRange[1] ? moment(debDateRange[1]).format('L') : undefined;
  const [debAssignee] = useDebouncedValue(assignee, debounceDuration);

  const { data, isLoading, isError, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useRiskListInfinite(
      {
        query: {
          project: project.id,
          assignee: debAssignee,
          category: debCategories,
          created_after: date_range_from,
          created_before: date_range_to,
          severity: debSeverities,
          status: debStatuses as unknown as RiskStatusEnum,
          tag: debTags,
          sortBy: 'createdAt',
          sortOrder: order,
        },
      },
      undefined,
      {
        initialPageParam: 1,
        getNextPageParam: (data) => {
          const pageData = data as unknown as RiskListDto;
          return pageData.nextPage;
        },
        retry: false,
      }
    );

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView, hasNextPage]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    return <div>Error!</div>;
  }

  return data?.pages.map((page) => (
    <Fragment key={page?.page}>
      {page?.results
        ?.filter((risk) => (hideRejected ? risk.status !== RiskStatusEnum.REJECTED : true))
        .map((risk) => <RiskCard projectId={project.id} key={risk.id} layout="Row" risk={risk} />)}

      <div ref={ref} />
      {isFetchingNextPage && (
        <Center>
          <Loader color="blue" type="dots" />
        </Center>
      )}
    </Fragment>
  ));
});

RiskCards.displayName = 'RiskCards';
