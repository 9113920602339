// generated with @7nohe/openapi-react-query-codegen@2.0.0-beta.3

import { type Options } from '@hey-api/client-axios';
import { type QueryClient } from '@tanstack/react-query';
import {
  adminCompanyList,
  adminContractList,
  adminProjectList,
  adminRiskList,
  adminUserList,
  assetDiscoveryRunList,
  assetDiscoveryRunRetrieve,
  attachmentRetrieve,
  commentList,
  companyInvitationList,
  companyList,
  companyRetrieve,
  companyTagsList,
  contractList,
  contractRetrieve,
  domainList,
  domainRetrieve,
  emailTemplateList,
  emailTemplateRetrieve,
  health,
  projectDetailsRetrieve,
  projectList,
  projectRetrieve,
  projectSubmissionsStats,
  proposalList,
  riskAvailableStatuses,
  riskExport,
  riskHistoryList,
  riskLinkedRisksList,
  riskList,
  riskRetrieve,
  riskTagList,
  statsCompany,
  statsProject,
  statsRisksProjectsContractsCompanies,
  submissionAvailableStatuses,
  submissionCommentList,
  submissionExport,
  submissionList,
  submissionRetrieve,
  submissionRiskLinkList,
  submissionTagList,
  unlockRequestList,
  userMeRetrieve,
  userRetrieve,
} from '../requests/services.gen';
import {
  AdminCompanyListData,
  AdminContractListData,
  AdminProjectListData,
  AdminRiskListData,
  AdminUserListData,
  AssetDiscoveryRunListData,
  AssetDiscoveryRunRetrieveData,
  AttachmentRetrieveData,
  CommentListData,
  CompanyInvitationListData,
  CompanyRetrieveData,
  CompanyTagsListData,
  ContractListData,
  ContractRetrieveData,
  DomainListData,
  DomainRetrieveData,
  EmailTemplateListData,
  EmailTemplateRetrieveData,
  ProjectDetailsRetrieveData,
  ProjectRetrieveData,
  ProjectSubmissionsStatsData,
  ProposalListData,
  RiskAvailableStatusesData,
  RiskExportData,
  RiskHistoryListData,
  RiskLinkedRisksListData,
  RiskListData,
  RiskRetrieveData,
  RiskTagListData,
  StatsCompanyData,
  StatsProjectData,
  SubmissionAvailableStatusesData,
  SubmissionCommentListData,
  SubmissionExportData,
  SubmissionListData,
  SubmissionRetrieveData,
  SubmissionRiskLinkListData,
  SubmissionTagListData,
  UnlockRequestListData,
  UserRetrieveData,
} from '../requests/types.gen';
import * as Common from './common';
export const ensureUseHealthData = (
  queryClient: QueryClient,
  clientOptions: Options<unknown, true> = {}
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseHealthKeyFn(clientOptions),
    queryFn: () => health({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseUserMeRetrieveData = (
  queryClient: QueryClient,
  clientOptions: Options<unknown, true> = {}
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseUserMeRetrieveKeyFn(clientOptions),
    queryFn: () => userMeRetrieve({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseAdminUserListData = (
  queryClient: QueryClient,
  clientOptions: Options<AdminUserListData, true> = {}
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseAdminUserListKeyFn(clientOptions),
    queryFn: () => adminUserList({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseUserRetrieveData = (
  queryClient: QueryClient,
  clientOptions: Options<UserRetrieveData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseUserRetrieveKeyFn(clientOptions),
    queryFn: () => userRetrieve({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseCompanyListData = (
  queryClient: QueryClient,
  clientOptions: Options<unknown, true> = {}
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseCompanyListKeyFn(clientOptions),
    queryFn: () => companyList({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseAdminCompanyListData = (
  queryClient: QueryClient,
  clientOptions: Options<AdminCompanyListData, true> = {}
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseAdminCompanyListKeyFn(clientOptions),
    queryFn: () => adminCompanyList({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseCompanyTagsListData = (
  queryClient: QueryClient,
  clientOptions: Options<CompanyTagsListData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseCompanyTagsListKeyFn(clientOptions),
    queryFn: () => companyTagsList({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseCompanyRetrieveData = (
  queryClient: QueryClient,
  clientOptions: Options<CompanyRetrieveData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseCompanyRetrieveKeyFn(clientOptions),
    queryFn: () => companyRetrieve({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseCompanyInvitationListData = (
  queryClient: QueryClient,
  clientOptions: Options<CompanyInvitationListData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseCompanyInvitationListKeyFn(clientOptions),
    queryFn: () => companyInvitationList({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseProjectListData = (
  queryClient: QueryClient,
  clientOptions: Options<unknown, true> = {}
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseProjectListKeyFn(clientOptions),
    queryFn: () => projectList({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseAdminProjectListData = (
  queryClient: QueryClient,
  clientOptions: Options<AdminProjectListData, true> = {}
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseAdminProjectListKeyFn(clientOptions),
    queryFn: () => adminProjectList({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseProjectRetrieveData = (
  queryClient: QueryClient,
  clientOptions: Options<ProjectRetrieveData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseProjectRetrieveKeyFn(clientOptions),
    queryFn: () => projectRetrieve({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseProjectDetailsRetrieveData = (
  queryClient: QueryClient,
  clientOptions: Options<ProjectDetailsRetrieveData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseProjectDetailsRetrieveKeyFn(clientOptions),
    queryFn: () => projectDetailsRetrieve({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseProjectSubmissionsStatsData = (
  queryClient: QueryClient,
  clientOptions: Options<ProjectSubmissionsStatsData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseProjectSubmissionsStatsKeyFn(clientOptions),
    queryFn: () => projectSubmissionsStats({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseRiskListData = (
  queryClient: QueryClient,
  clientOptions: Options<RiskListData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseRiskListKeyFn(clientOptions),
    queryFn: () => riskList({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseAdminRiskListData = (
  queryClient: QueryClient,
  clientOptions: Options<AdminRiskListData, true> = {}
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseAdminRiskListKeyFn(clientOptions),
    queryFn: () => adminRiskList({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseRiskExportData = (
  queryClient: QueryClient,
  clientOptions: Options<RiskExportData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseRiskExportKeyFn(clientOptions),
    queryFn: () => riskExport({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseUnlockRequestListData = (
  queryClient: QueryClient,
  clientOptions: Options<UnlockRequestListData, true> = {}
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseUnlockRequestListKeyFn(clientOptions),
    queryFn: () => unlockRequestList({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseRiskTagListData = (
  queryClient: QueryClient,
  clientOptions: Options<RiskTagListData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseRiskTagListKeyFn(clientOptions),
    queryFn: () => riskTagList({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseRiskRetrieveData = (
  queryClient: QueryClient,
  clientOptions: Options<RiskRetrieveData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseRiskRetrieveKeyFn(clientOptions),
    queryFn: () => riskRetrieve({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseRiskHistoryListData = (
  queryClient: QueryClient,
  clientOptions: Options<RiskHistoryListData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseRiskHistoryListKeyFn(clientOptions),
    queryFn: () => riskHistoryList({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseRiskLinkedRisksListData = (
  queryClient: QueryClient,
  clientOptions: Options<RiskLinkedRisksListData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseRiskLinkedRisksListKeyFn(clientOptions),
    queryFn: () => riskLinkedRisksList({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseCommentListData = (
  queryClient: QueryClient,
  clientOptions: Options<CommentListData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseCommentListKeyFn(clientOptions),
    queryFn: () => commentList({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseRiskAvailableStatusesData = (
  queryClient: QueryClient,
  clientOptions: Options<RiskAvailableStatusesData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseRiskAvailableStatusesKeyFn(clientOptions),
    queryFn: () => riskAvailableStatuses({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseContractListData = (
  queryClient: QueryClient,
  clientOptions: Options<ContractListData, true> = {}
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseContractListKeyFn(clientOptions),
    queryFn: () => contractList({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseAdminContractListData = (
  queryClient: QueryClient,
  clientOptions: Options<AdminContractListData, true> = {}
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseAdminContractListKeyFn(clientOptions),
    queryFn: () => adminContractList({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseContractRetrieveData = (
  queryClient: QueryClient,
  clientOptions: Options<ContractRetrieveData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseContractRetrieveKeyFn(clientOptions),
    queryFn: () => contractRetrieve({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseProposalListData = (
  queryClient: QueryClient,
  clientOptions: Options<ProposalListData, true> = {}
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseProposalListKeyFn(clientOptions),
    queryFn: () => proposalList({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseAttachmentRetrieveData = (
  queryClient: QueryClient,
  clientOptions: Options<AttachmentRetrieveData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseAttachmentRetrieveKeyFn(clientOptions),
    queryFn: () => attachmentRetrieve({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseStatsRisksProjectsContractsCompaniesData = (
  queryClient: QueryClient,
  clientOptions: Options<unknown, true> = {}
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseStatsRisksProjectsContractsCompaniesKeyFn(clientOptions),
    queryFn: () =>
      statsRisksProjectsContractsCompanies({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseStatsCompanyData = (
  queryClient: QueryClient,
  clientOptions: Options<StatsCompanyData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseStatsCompanyKeyFn(clientOptions),
    queryFn: () => statsCompany({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseStatsProjectData = (
  queryClient: QueryClient,
  clientOptions: Options<StatsProjectData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseStatsProjectKeyFn(clientOptions),
    queryFn: () => statsProject({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseEmailTemplateListData = (
  queryClient: QueryClient,
  clientOptions: Options<EmailTemplateListData, true> = {}
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseEmailTemplateListKeyFn(clientOptions),
    queryFn: () => emailTemplateList({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseEmailTemplateRetrieveData = (
  queryClient: QueryClient,
  clientOptions: Options<EmailTemplateRetrieveData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseEmailTemplateRetrieveKeyFn(clientOptions),
    queryFn: () => emailTemplateRetrieve({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseSubmissionListData = (
  queryClient: QueryClient,
  clientOptions: Options<SubmissionListData, true> = {}
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseSubmissionListKeyFn(clientOptions),
    queryFn: () => submissionList({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseSubmissionExportData = (
  queryClient: QueryClient,
  clientOptions: Options<SubmissionExportData, true> = {}
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseSubmissionExportKeyFn(clientOptions),
    queryFn: () => submissionExport({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseSubmissionRetrieveData = (
  queryClient: QueryClient,
  clientOptions: Options<SubmissionRetrieveData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseSubmissionRetrieveKeyFn(clientOptions),
    queryFn: () => submissionRetrieve({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseSubmissionAvailableStatusesData = (
  queryClient: QueryClient,
  clientOptions: Options<SubmissionAvailableStatusesData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseSubmissionAvailableStatusesKeyFn(clientOptions),
    queryFn: () =>
      submissionAvailableStatuses({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseSubmissionRiskLinkListData = (
  queryClient: QueryClient,
  clientOptions: Options<SubmissionRiskLinkListData, true> = {}
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseSubmissionRiskLinkListKeyFn(clientOptions),
    queryFn: () => submissionRiskLinkList({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseSubmissionCommentListData = (
  queryClient: QueryClient,
  clientOptions: Options<SubmissionCommentListData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseSubmissionCommentListKeyFn(clientOptions),
    queryFn: () => submissionCommentList({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseSubmissionTagListData = (
  queryClient: QueryClient,
  clientOptions: Options<SubmissionTagListData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseSubmissionTagListKeyFn(clientOptions),
    queryFn: () => submissionTagList({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseAssetDiscoveryRunRetrieveData = (
  queryClient: QueryClient,
  clientOptions: Options<AssetDiscoveryRunRetrieveData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseAssetDiscoveryRunRetrieveKeyFn(clientOptions),
    queryFn: () =>
      assetDiscoveryRunRetrieve({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseAssetDiscoveryRunListData = (
  queryClient: QueryClient,
  clientOptions: Options<AssetDiscoveryRunListData, true> = {}
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseAssetDiscoveryRunListKeyFn(clientOptions),
    queryFn: () => assetDiscoveryRunList({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseDomainListData = (
  queryClient: QueryClient,
  clientOptions: Options<DomainListData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseDomainListKeyFn(clientOptions),
    queryFn: () => domainList({ ...clientOptions }).then((response) => response.data),
  });
export const ensureUseDomainRetrieveData = (
  queryClient: QueryClient,
  clientOptions: Options<DomainRetrieveData, true>
) =>
  queryClient.ensureQueryData({
    queryKey: Common.UseDomainRetrieveKeyFn(clientOptions),
    queryFn: () => domainRetrieve({ ...clientOptions }).then((response) => response.data),
  });
