import { Grid, Stack } from '@mantine/core';
import { useUserType } from '@/hooks/use-user-type';
import { ProjectDto, UserTypeEnum } from '@/openapi/requests';
import SubmissionCards from './SubmissionCards';

interface ListLayoutProps {
  project: ProjectDto;
}

export const ListLayout = ({ project }: ListLayoutProps) => {
  const userType = useUserType();
  const showOverviewCard = userType !== UserTypeEnum.RESEARCHER;

  return (
    <Grid>
      <Grid.Col
        span={{
          md: showOverviewCard ? 8 : undefined,
        }}
      >
        <Stack gap="md" pt="sm">
          <SubmissionCards project={project} />
        </Stack>
      </Grid.Col>
    </Grid>
  );
};
