// generated with @7nohe/openapi-react-query-codegen@2.0.0-beta.3

import { type Options } from '@hey-api/client-axios';
import { UseQueryResult } from '@tanstack/react-query';
import {
  addDomain,
  adminCompanyList,
  adminContractList,
  adminProjectList,
  adminRiskList,
  adminUserList,
  assetDiscoveryRunList,
  assetDiscoveryRunRetrieve,
  attachmentCreate,
  attachmentPresignedUrlCreate,
  attachmentRetrieve,
  authLogin,
  authLogout,
  commentList,
  companyAddUser,
  companyArchive,
  companyCreate,
  companyDeleteUser,
  companyInvitationAcceptExistingUser,
  companyInvitationAcceptNewUser,
  companyInvitationCreate,
  companyInvitationDelete,
  companyInvitationList,
  companyInvitationResend,
  companyInvitationValidate,
  companyList,
  companyRetrieve,
  companyTagsList,
  companyUpdate,
  contractCreate,
  contractList,
  contractRetrieve,
  contractUpdate,
  deleteDomain,
  deleteDomainAsset,
  domainList,
  domainRetrieve,
  emailTemplateList,
  emailTemplateRetrieve,
  emailTemplateUpdate,
  health,
  passwordResetConfirm,
  passwordResetCreate,
  passwordResetValidate,
  projectArchive,
  projectCreate,
  projectDetailsRetrieve,
  projectList,
  projectLogoPresignedUrlCreate,
  projectLogoPresignedUrlCreate1,
  projectPartialUpdate,
  projectRetrieve,
  projectSubmissionsStats,
  projectUpdate,
  proposalList,
  refreshToken,
  riskAvailableStatuses,
  riskCommentCreate,
  riskCommentDelete,
  riskCommentPartialUpdate,
  riskCreate,
  riskExport,
  riskHistoryList,
  riskLinkCreate,
  riskLinkedRisksList,
  riskList,
  riskPartialUpdate,
  riskRetrieve,
  riskTagCreate,
  riskTagDelete,
  riskTagList,
  riskUnlinkCreate,
  riskUpdate,
  scheduleAssetDiscoveryRun,
  statsCompany,
  statsProject,
  statsRisksProjectsContractsCompanies,
  submissionAvailableStatuses,
  submissionCommentCreate,
  submissionCommentDelete,
  submissionCommentList,
  submissionCommentUpdate,
  submissionCreate,
  submissionDelete,
  submissionExport,
  submissionList,
  submissionRetrieve,
  submissionRiskCreate,
  submissionRiskLinkCreate,
  submissionRiskLinkDelete,
  submissionRiskLinkList,
  submissionRiskLinkUnlink,
  submissionTagCreate,
  submissionTagDelete,
  submissionTagList,
  submissionUpdate,
  submissionUpdateStatus,
  tagCreate,
  tagDelete,
  tagUpdate,
  unlockRequestApprove,
  unlockRequestCreate,
  unlockRequestList,
  unlockRequestReject,
  updateAssetDiscoveryRunAssets,
  updateAssetDiscoveryRunStatus,
  updateAssetIpEnumeration,
  updateDomain,
  userCreate,
  userMeRetrieve,
  userRetrieve,
  userUpdate,
  userUpdateById,
} from '../requests/services.gen';
export type HealthDefaultResponse = Awaited<ReturnType<typeof health>>['data'];
export type HealthQueryResult<TData = HealthDefaultResponse, TError = unknown> = UseQueryResult<
  TData,
  TError
>;
export const useHealthKey = 'Health';
export const UseHealthKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useHealthKey, ...(queryKey ?? [clientOptions])];
export type UserMeRetrieveDefaultResponse = Awaited<ReturnType<typeof userMeRetrieve>>['data'];
export type UserMeRetrieveQueryResult<
  TData = UserMeRetrieveDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useUserMeRetrieveKey = 'UserMeRetrieve';
export const UseUserMeRetrieveKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useUserMeRetrieveKey, ...(queryKey ?? [clientOptions])];
export type AdminUserListDefaultResponse = Awaited<ReturnType<typeof adminUserList>>['data'];
export type AdminUserListQueryResult<
  TData = AdminUserListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useAdminUserListKey = 'AdminUserList';
export const UseAdminUserListKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useAdminUserListKey, ...(queryKey ?? [clientOptions])];
export type UserRetrieveDefaultResponse = Awaited<ReturnType<typeof userRetrieve>>['data'];
export type UserRetrieveQueryResult<
  TData = UserRetrieveDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useUserRetrieveKey = 'UserRetrieve';
export const UseUserRetrieveKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useUserRetrieveKey, ...(queryKey ?? [clientOptions])];
export type CompanyListDefaultResponse = Awaited<ReturnType<typeof companyList>>['data'];
export type CompanyListQueryResult<
  TData = CompanyListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useCompanyListKey = 'CompanyList';
export const UseCompanyListKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useCompanyListKey, ...(queryKey ?? [clientOptions])];
export type AdminCompanyListDefaultResponse = Awaited<ReturnType<typeof adminCompanyList>>['data'];
export type AdminCompanyListQueryResult<
  TData = AdminCompanyListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useAdminCompanyListKey = 'AdminCompanyList';
export const UseAdminCompanyListKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useAdminCompanyListKey, ...(queryKey ?? [clientOptions])];
export type CompanyTagsListDefaultResponse = Awaited<ReturnType<typeof companyTagsList>>['data'];
export type CompanyTagsListQueryResult<
  TData = CompanyTagsListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useCompanyTagsListKey = 'CompanyTagsList';
export const UseCompanyTagsListKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useCompanyTagsListKey, ...(queryKey ?? [clientOptions])];
export type CompanyRetrieveDefaultResponse = Awaited<ReturnType<typeof companyRetrieve>>['data'];
export type CompanyRetrieveQueryResult<
  TData = CompanyRetrieveDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useCompanyRetrieveKey = 'CompanyRetrieve';
export const UseCompanyRetrieveKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useCompanyRetrieveKey, ...(queryKey ?? [clientOptions])];
export type CompanyInvitationListDefaultResponse = Awaited<
  ReturnType<typeof companyInvitationList>
>['data'];
export type CompanyInvitationListQueryResult<
  TData = CompanyInvitationListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useCompanyInvitationListKey = 'CompanyInvitationList';
export const UseCompanyInvitationListKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useCompanyInvitationListKey, ...(queryKey ?? [clientOptions])];
export type ProjectListDefaultResponse = Awaited<ReturnType<typeof projectList>>['data'];
export type ProjectListQueryResult<
  TData = ProjectListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useProjectListKey = 'ProjectList';
export const UseProjectListKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useProjectListKey, ...(queryKey ?? [clientOptions])];
export type AdminProjectListDefaultResponse = Awaited<ReturnType<typeof adminProjectList>>['data'];
export type AdminProjectListQueryResult<
  TData = AdminProjectListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useAdminProjectListKey = 'AdminProjectList';
export const UseAdminProjectListKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useAdminProjectListKey, ...(queryKey ?? [clientOptions])];
export type ProjectRetrieveDefaultResponse = Awaited<ReturnType<typeof projectRetrieve>>['data'];
export type ProjectRetrieveQueryResult<
  TData = ProjectRetrieveDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useProjectRetrieveKey = 'ProjectRetrieve';
export const UseProjectRetrieveKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useProjectRetrieveKey, ...(queryKey ?? [clientOptions])];
export type ProjectDetailsRetrieveDefaultResponse = Awaited<
  ReturnType<typeof projectDetailsRetrieve>
>['data'];
export type ProjectDetailsRetrieveQueryResult<
  TData = ProjectDetailsRetrieveDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useProjectDetailsRetrieveKey = 'ProjectDetailsRetrieve';
export const UseProjectDetailsRetrieveKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useProjectDetailsRetrieveKey, ...(queryKey ?? [clientOptions])];
export type ProjectSubmissionsStatsDefaultResponse = Awaited<
  ReturnType<typeof projectSubmissionsStats>
>['data'];
export type ProjectSubmissionsStatsQueryResult<
  TData = ProjectSubmissionsStatsDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useProjectSubmissionsStatsKey = 'ProjectSubmissionsStats';
export const UseProjectSubmissionsStatsKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useProjectSubmissionsStatsKey, ...(queryKey ?? [clientOptions])];
export type RiskListDefaultResponse = Awaited<ReturnType<typeof riskList>>['data'];
export type RiskListQueryResult<TData = RiskListDefaultResponse, TError = unknown> = UseQueryResult<
  TData,
  TError
>;
export const useRiskListKey = 'RiskList';
export const UseRiskListKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useRiskListKey, ...(queryKey ?? [clientOptions])];
export type AdminRiskListDefaultResponse = Awaited<ReturnType<typeof adminRiskList>>['data'];
export type AdminRiskListQueryResult<
  TData = AdminRiskListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useAdminRiskListKey = 'AdminRiskList';
export const UseAdminRiskListKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useAdminRiskListKey, ...(queryKey ?? [clientOptions])];
export type RiskExportDefaultResponse = Awaited<ReturnType<typeof riskExport>>['data'];
export type RiskExportQueryResult<
  TData = RiskExportDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useRiskExportKey = 'RiskExport';
export const UseRiskExportKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useRiskExportKey, ...(queryKey ?? [clientOptions])];
export type UnlockRequestListDefaultResponse = Awaited<
  ReturnType<typeof unlockRequestList>
>['data'];
export type UnlockRequestListQueryResult<
  TData = UnlockRequestListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useUnlockRequestListKey = 'UnlockRequestList';
export const UseUnlockRequestListKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useUnlockRequestListKey, ...(queryKey ?? [clientOptions])];
export type RiskTagListDefaultResponse = Awaited<ReturnType<typeof riskTagList>>['data'];
export type RiskTagListQueryResult<
  TData = RiskTagListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useRiskTagListKey = 'RiskTagList';
export const UseRiskTagListKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useRiskTagListKey, ...(queryKey ?? [clientOptions])];
export type RiskRetrieveDefaultResponse = Awaited<ReturnType<typeof riskRetrieve>>['data'];
export type RiskRetrieveQueryResult<
  TData = RiskRetrieveDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useRiskRetrieveKey = 'RiskRetrieve';
export const UseRiskRetrieveKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useRiskRetrieveKey, ...(queryKey ?? [clientOptions])];
export type RiskHistoryListDefaultResponse = Awaited<ReturnType<typeof riskHistoryList>>['data'];
export type RiskHistoryListQueryResult<
  TData = RiskHistoryListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useRiskHistoryListKey = 'RiskHistoryList';
export const UseRiskHistoryListKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useRiskHistoryListKey, ...(queryKey ?? [clientOptions])];
export type RiskLinkedRisksListDefaultResponse = Awaited<
  ReturnType<typeof riskLinkedRisksList>
>['data'];
export type RiskLinkedRisksListQueryResult<
  TData = RiskLinkedRisksListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useRiskLinkedRisksListKey = 'RiskLinkedRisksList';
export const UseRiskLinkedRisksListKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useRiskLinkedRisksListKey, ...(queryKey ?? [clientOptions])];
export type CommentListDefaultResponse = Awaited<ReturnType<typeof commentList>>['data'];
export type CommentListQueryResult<
  TData = CommentListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useCommentListKey = 'CommentList';
export const UseCommentListKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useCommentListKey, ...(queryKey ?? [clientOptions])];
export type RiskAvailableStatusesDefaultResponse = Awaited<
  ReturnType<typeof riskAvailableStatuses>
>['data'];
export type RiskAvailableStatusesQueryResult<
  TData = RiskAvailableStatusesDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useRiskAvailableStatusesKey = 'RiskAvailableStatuses';
export const UseRiskAvailableStatusesKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useRiskAvailableStatusesKey, ...(queryKey ?? [clientOptions])];
export type ContractListDefaultResponse = Awaited<ReturnType<typeof contractList>>['data'];
export type ContractListQueryResult<
  TData = ContractListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useContractListKey = 'ContractList';
export const UseContractListKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useContractListKey, ...(queryKey ?? [clientOptions])];
export type AdminContractListDefaultResponse = Awaited<
  ReturnType<typeof adminContractList>
>['data'];
export type AdminContractListQueryResult<
  TData = AdminContractListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useAdminContractListKey = 'AdminContractList';
export const UseAdminContractListKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useAdminContractListKey, ...(queryKey ?? [clientOptions])];
export type ContractRetrieveDefaultResponse = Awaited<ReturnType<typeof contractRetrieve>>['data'];
export type ContractRetrieveQueryResult<
  TData = ContractRetrieveDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useContractRetrieveKey = 'ContractRetrieve';
export const UseContractRetrieveKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useContractRetrieveKey, ...(queryKey ?? [clientOptions])];
export type ProposalListDefaultResponse = Awaited<ReturnType<typeof proposalList>>['data'];
export type ProposalListQueryResult<
  TData = ProposalListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useProposalListKey = 'ProposalList';
export const UseProposalListKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useProposalListKey, ...(queryKey ?? [clientOptions])];
export type AttachmentRetrieveDefaultResponse = Awaited<
  ReturnType<typeof attachmentRetrieve>
>['data'];
export type AttachmentRetrieveQueryResult<
  TData = AttachmentRetrieveDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useAttachmentRetrieveKey = 'AttachmentRetrieve';
export const UseAttachmentRetrieveKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useAttachmentRetrieveKey, ...(queryKey ?? [clientOptions])];
export type StatsRisksProjectsContractsCompaniesDefaultResponse = Awaited<
  ReturnType<typeof statsRisksProjectsContractsCompanies>
>['data'];
export type StatsRisksProjectsContractsCompaniesQueryResult<
  TData = StatsRisksProjectsContractsCompaniesDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useStatsRisksProjectsContractsCompaniesKey = 'StatsRisksProjectsContractsCompanies';
export const UseStatsRisksProjectsContractsCompaniesKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useStatsRisksProjectsContractsCompaniesKey, ...(queryKey ?? [clientOptions])];
export type StatsCompanyDefaultResponse = Awaited<ReturnType<typeof statsCompany>>['data'];
export type StatsCompanyQueryResult<
  TData = StatsCompanyDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useStatsCompanyKey = 'StatsCompany';
export const UseStatsCompanyKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useStatsCompanyKey, ...(queryKey ?? [clientOptions])];
export type StatsProjectDefaultResponse = Awaited<ReturnType<typeof statsProject>>['data'];
export type StatsProjectQueryResult<
  TData = StatsProjectDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useStatsProjectKey = 'StatsProject';
export const UseStatsProjectKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useStatsProjectKey, ...(queryKey ?? [clientOptions])];
export type EmailTemplateListDefaultResponse = Awaited<
  ReturnType<typeof emailTemplateList>
>['data'];
export type EmailTemplateListQueryResult<
  TData = EmailTemplateListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useEmailTemplateListKey = 'EmailTemplateList';
export const UseEmailTemplateListKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useEmailTemplateListKey, ...(queryKey ?? [clientOptions])];
export type EmailTemplateRetrieveDefaultResponse = Awaited<
  ReturnType<typeof emailTemplateRetrieve>
>['data'];
export type EmailTemplateRetrieveQueryResult<
  TData = EmailTemplateRetrieveDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useEmailTemplateRetrieveKey = 'EmailTemplateRetrieve';
export const UseEmailTemplateRetrieveKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useEmailTemplateRetrieveKey, ...(queryKey ?? [clientOptions])];
export type SubmissionListDefaultResponse = Awaited<ReturnType<typeof submissionList>>['data'];
export type SubmissionListQueryResult<
  TData = SubmissionListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useSubmissionListKey = 'SubmissionList';
export const UseSubmissionListKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useSubmissionListKey, ...(queryKey ?? [clientOptions])];
export type SubmissionExportDefaultResponse = Awaited<ReturnType<typeof submissionExport>>['data'];
export type SubmissionExportQueryResult<
  TData = SubmissionExportDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useSubmissionExportKey = 'SubmissionExport';
export const UseSubmissionExportKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useSubmissionExportKey, ...(queryKey ?? [clientOptions])];
export type SubmissionRetrieveDefaultResponse = Awaited<
  ReturnType<typeof submissionRetrieve>
>['data'];
export type SubmissionRetrieveQueryResult<
  TData = SubmissionRetrieveDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useSubmissionRetrieveKey = 'SubmissionRetrieve';
export const UseSubmissionRetrieveKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useSubmissionRetrieveKey, ...(queryKey ?? [clientOptions])];
export type SubmissionAvailableStatusesDefaultResponse = Awaited<
  ReturnType<typeof submissionAvailableStatuses>
>['data'];
export type SubmissionAvailableStatusesQueryResult<
  TData = SubmissionAvailableStatusesDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useSubmissionAvailableStatusesKey = 'SubmissionAvailableStatuses';
export const UseSubmissionAvailableStatusesKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useSubmissionAvailableStatusesKey, ...(queryKey ?? [clientOptions])];
export type SubmissionRiskLinkListDefaultResponse = Awaited<
  ReturnType<typeof submissionRiskLinkList>
>['data'];
export type SubmissionRiskLinkListQueryResult<
  TData = SubmissionRiskLinkListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useSubmissionRiskLinkListKey = 'SubmissionRiskLinkList';
export const UseSubmissionRiskLinkListKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useSubmissionRiskLinkListKey, ...(queryKey ?? [clientOptions])];
export type SubmissionCommentListDefaultResponse = Awaited<
  ReturnType<typeof submissionCommentList>
>['data'];
export type SubmissionCommentListQueryResult<
  TData = SubmissionCommentListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useSubmissionCommentListKey = 'SubmissionCommentList';
export const UseSubmissionCommentListKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useSubmissionCommentListKey, ...(queryKey ?? [clientOptions])];
export type SubmissionTagListDefaultResponse = Awaited<
  ReturnType<typeof submissionTagList>
>['data'];
export type SubmissionTagListQueryResult<
  TData = SubmissionTagListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useSubmissionTagListKey = 'SubmissionTagList';
export const UseSubmissionTagListKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useSubmissionTagListKey, ...(queryKey ?? [clientOptions])];
export type AssetDiscoveryRunRetrieveDefaultResponse = Awaited<
  ReturnType<typeof assetDiscoveryRunRetrieve>
>['data'];
export type AssetDiscoveryRunRetrieveQueryResult<
  TData = AssetDiscoveryRunRetrieveDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useAssetDiscoveryRunRetrieveKey = 'AssetDiscoveryRunRetrieve';
export const UseAssetDiscoveryRunRetrieveKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useAssetDiscoveryRunRetrieveKey, ...(queryKey ?? [clientOptions])];
export type AssetDiscoveryRunListDefaultResponse = Awaited<
  ReturnType<typeof assetDiscoveryRunList>
>['data'];
export type AssetDiscoveryRunListQueryResult<
  TData = AssetDiscoveryRunListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useAssetDiscoveryRunListKey = 'AssetDiscoveryRunList';
export const UseAssetDiscoveryRunListKeyFn = (
  clientOptions: Options<unknown, true> = {},
  queryKey?: Array<unknown>
) => [useAssetDiscoveryRunListKey, ...(queryKey ?? [clientOptions])];
export type DomainListDefaultResponse = Awaited<ReturnType<typeof domainList>>['data'];
export type DomainListQueryResult<
  TData = DomainListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useDomainListKey = 'DomainList';
export const UseDomainListKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useDomainListKey, ...(queryKey ?? [clientOptions])];
export type DomainRetrieveDefaultResponse = Awaited<ReturnType<typeof domainRetrieve>>['data'];
export type DomainRetrieveQueryResult<
  TData = DomainRetrieveDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useDomainRetrieveKey = 'DomainRetrieve';
export const UseDomainRetrieveKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useDomainRetrieveKey, ...(queryKey ?? [clientOptions])];
export type AuthLoginMutationResult = Awaited<ReturnType<typeof authLogin>>;
export const useAuthLoginKey = 'AuthLogin';
export const UseAuthLoginKeyFn = (mutationKey?: Array<unknown>) => [
  useAuthLoginKey,
  ...(mutationKey ?? []),
];
export type AuthLogoutMutationResult = Awaited<ReturnType<typeof authLogout>>;
export const useAuthLogoutKey = 'AuthLogout';
export const UseAuthLogoutKeyFn = (mutationKey?: Array<unknown>) => [
  useAuthLogoutKey,
  ...(mutationKey ?? []),
];
export type PasswordResetCreateMutationResult = Awaited<ReturnType<typeof passwordResetCreate>>;
export const usePasswordResetCreateKey = 'PasswordResetCreate';
export const UsePasswordResetCreateKeyFn = (mutationKey?: Array<unknown>) => [
  usePasswordResetCreateKey,
  ...(mutationKey ?? []),
];
export type PasswordResetValidateMutationResult = Awaited<ReturnType<typeof passwordResetValidate>>;
export const usePasswordResetValidateKey = 'PasswordResetValidate';
export const UsePasswordResetValidateKeyFn = (mutationKey?: Array<unknown>) => [
  usePasswordResetValidateKey,
  ...(mutationKey ?? []),
];
export type PasswordResetConfirmMutationResult = Awaited<ReturnType<typeof passwordResetConfirm>>;
export const usePasswordResetConfirmKey = 'PasswordResetConfirm';
export const UsePasswordResetConfirmKeyFn = (mutationKey?: Array<unknown>) => [
  usePasswordResetConfirmKey,
  ...(mutationKey ?? []),
];
export type RefreshTokenMutationResult = Awaited<ReturnType<typeof refreshToken>>;
export const useRefreshTokenKey = 'RefreshToken';
export const UseRefreshTokenKeyFn = (mutationKey?: Array<unknown>) => [
  useRefreshTokenKey,
  ...(mutationKey ?? []),
];
export type UserCreateMutationResult = Awaited<ReturnType<typeof userCreate>>;
export const useUserCreateKey = 'UserCreate';
export const UseUserCreateKeyFn = (mutationKey?: Array<unknown>) => [
  useUserCreateKey,
  ...(mutationKey ?? []),
];
export type CompanyCreateMutationResult = Awaited<ReturnType<typeof companyCreate>>;
export const useCompanyCreateKey = 'CompanyCreate';
export const UseCompanyCreateKeyFn = (mutationKey?: Array<unknown>) => [
  useCompanyCreateKey,
  ...(mutationKey ?? []),
];
export type CompanyInvitationCreateMutationResult = Awaited<
  ReturnType<typeof companyInvitationCreate>
>;
export const useCompanyInvitationCreateKey = 'CompanyInvitationCreate';
export const UseCompanyInvitationCreateKeyFn = (mutationKey?: Array<unknown>) => [
  useCompanyInvitationCreateKey,
  ...(mutationKey ?? []),
];
export type CompanyInvitationValidateMutationResult = Awaited<
  ReturnType<typeof companyInvitationValidate>
>;
export const useCompanyInvitationValidateKey = 'CompanyInvitationValidate';
export const UseCompanyInvitationValidateKeyFn = (mutationKey?: Array<unknown>) => [
  useCompanyInvitationValidateKey,
  ...(mutationKey ?? []),
];
export type CompanyInvitationAcceptNewUserMutationResult = Awaited<
  ReturnType<typeof companyInvitationAcceptNewUser>
>;
export const useCompanyInvitationAcceptNewUserKey = 'CompanyInvitationAcceptNewUser';
export const UseCompanyInvitationAcceptNewUserKeyFn = (mutationKey?: Array<unknown>) => [
  useCompanyInvitationAcceptNewUserKey,
  ...(mutationKey ?? []),
];
export type CompanyInvitationAcceptExistingUserMutationResult = Awaited<
  ReturnType<typeof companyInvitationAcceptExistingUser>
>;
export const useCompanyInvitationAcceptExistingUserKey = 'CompanyInvitationAcceptExistingUser';
export const UseCompanyInvitationAcceptExistingUserKeyFn = (mutationKey?: Array<unknown>) => [
  useCompanyInvitationAcceptExistingUserKey,
  ...(mutationKey ?? []),
];
export type CompanyInvitationResendMutationResult = Awaited<
  ReturnType<typeof companyInvitationResend>
>;
export const useCompanyInvitationResendKey = 'CompanyInvitationResend';
export const UseCompanyInvitationResendKeyFn = (mutationKey?: Array<unknown>) => [
  useCompanyInvitationResendKey,
  ...(mutationKey ?? []),
];
export type CompanyAddUserMutationResult = Awaited<ReturnType<typeof companyAddUser>>;
export const useCompanyAddUserKey = 'CompanyAddUser';
export const UseCompanyAddUserKeyFn = (mutationKey?: Array<unknown>) => [
  useCompanyAddUserKey,
  ...(mutationKey ?? []),
];
export type ProjectCreateMutationResult = Awaited<ReturnType<typeof projectCreate>>;
export const useProjectCreateKey = 'ProjectCreate';
export const UseProjectCreateKeyFn = (mutationKey?: Array<unknown>) => [
  useProjectCreateKey,
  ...(mutationKey ?? []),
];
export type ProjectLogoPresignedUrlCreateMutationResult = Awaited<
  ReturnType<typeof projectLogoPresignedUrlCreate>
>;
export const useProjectLogoPresignedUrlCreateKey = 'ProjectLogoPresignedUrlCreate';
export const UseProjectLogoPresignedUrlCreateKeyFn = (mutationKey?: Array<unknown>) => [
  useProjectLogoPresignedUrlCreateKey,
  ...(mutationKey ?? []),
];
export type ProjectLogoPresignedUrlCreate1MutationResult = Awaited<
  ReturnType<typeof projectLogoPresignedUrlCreate1>
>;
export const useProjectLogoPresignedUrlCreate1Key = 'ProjectLogoPresignedUrlCreate1';
export const UseProjectLogoPresignedUrlCreate1KeyFn = (mutationKey?: Array<unknown>) => [
  useProjectLogoPresignedUrlCreate1Key,
  ...(mutationKey ?? []),
];
export type RiskCreateMutationResult = Awaited<ReturnType<typeof riskCreate>>;
export const useRiskCreateKey = 'RiskCreate';
export const UseRiskCreateKeyFn = (mutationKey?: Array<unknown>) => [
  useRiskCreateKey,
  ...(mutationKey ?? []),
];
export type UnlockRequestCreateMutationResult = Awaited<ReturnType<typeof unlockRequestCreate>>;
export const useUnlockRequestCreateKey = 'UnlockRequestCreate';
export const UseUnlockRequestCreateKeyFn = (mutationKey?: Array<unknown>) => [
  useUnlockRequestCreateKey,
  ...(mutationKey ?? []),
];
export type RiskLinkCreateMutationResult = Awaited<ReturnType<typeof riskLinkCreate>>;
export const useRiskLinkCreateKey = 'RiskLinkCreate';
export const UseRiskLinkCreateKeyFn = (mutationKey?: Array<unknown>) => [
  useRiskLinkCreateKey,
  ...(mutationKey ?? []),
];
export type RiskUnlinkCreateMutationResult = Awaited<ReturnType<typeof riskUnlinkCreate>>;
export const useRiskUnlinkCreateKey = 'RiskUnlinkCreate';
export const UseRiskUnlinkCreateKeyFn = (mutationKey?: Array<unknown>) => [
  useRiskUnlinkCreateKey,
  ...(mutationKey ?? []),
];
export type RiskCommentCreateMutationResult = Awaited<ReturnType<typeof riskCommentCreate>>;
export const useRiskCommentCreateKey = 'RiskCommentCreate';
export const UseRiskCommentCreateKeyFn = (mutationKey?: Array<unknown>) => [
  useRiskCommentCreateKey,
  ...(mutationKey ?? []),
];
export type ContractCreateMutationResult = Awaited<ReturnType<typeof contractCreate>>;
export const useContractCreateKey = 'ContractCreate';
export const UseContractCreateKeyFn = (mutationKey?: Array<unknown>) => [
  useContractCreateKey,
  ...(mutationKey ?? []),
];
export type TagCreateMutationResult = Awaited<ReturnType<typeof tagCreate>>;
export const useTagCreateKey = 'TagCreate';
export const UseTagCreateKeyFn = (mutationKey?: Array<unknown>) => [
  useTagCreateKey,
  ...(mutationKey ?? []),
];
export type RiskTagCreateMutationResult = Awaited<ReturnType<typeof riskTagCreate>>;
export const useRiskTagCreateKey = 'RiskTagCreate';
export const UseRiskTagCreateKeyFn = (mutationKey?: Array<unknown>) => [
  useRiskTagCreateKey,
  ...(mutationKey ?? []),
];
export type AttachmentPresignedUrlCreateMutationResult = Awaited<
  ReturnType<typeof attachmentPresignedUrlCreate>
>;
export const useAttachmentPresignedUrlCreateKey = 'AttachmentPresignedUrlCreate';
export const UseAttachmentPresignedUrlCreateKeyFn = (mutationKey?: Array<unknown>) => [
  useAttachmentPresignedUrlCreateKey,
  ...(mutationKey ?? []),
];
export type AttachmentCreateMutationResult = Awaited<ReturnType<typeof attachmentCreate>>;
export const useAttachmentCreateKey = 'AttachmentCreate';
export const UseAttachmentCreateKeyFn = (mutationKey?: Array<unknown>) => [
  useAttachmentCreateKey,
  ...(mutationKey ?? []),
];
export type SubmissionCreateMutationResult = Awaited<ReturnType<typeof submissionCreate>>;
export const useSubmissionCreateKey = 'SubmissionCreate';
export const UseSubmissionCreateKeyFn = (mutationKey?: Array<unknown>) => [
  useSubmissionCreateKey,
  ...(mutationKey ?? []),
];
export type SubmissionRiskCreateMutationResult = Awaited<ReturnType<typeof submissionRiskCreate>>;
export const useSubmissionRiskCreateKey = 'SubmissionRiskCreate';
export const UseSubmissionRiskCreateKeyFn = (mutationKey?: Array<unknown>) => [
  useSubmissionRiskCreateKey,
  ...(mutationKey ?? []),
];
export type SubmissionRiskLinkCreateMutationResult = Awaited<
  ReturnType<typeof submissionRiskLinkCreate>
>;
export const useSubmissionRiskLinkCreateKey = 'SubmissionRiskLinkCreate';
export const UseSubmissionRiskLinkCreateKeyFn = (mutationKey?: Array<unknown>) => [
  useSubmissionRiskLinkCreateKey,
  ...(mutationKey ?? []),
];
export type SubmissionCommentCreateMutationResult = Awaited<
  ReturnType<typeof submissionCommentCreate>
>;
export const useSubmissionCommentCreateKey = 'SubmissionCommentCreate';
export const UseSubmissionCommentCreateKeyFn = (mutationKey?: Array<unknown>) => [
  useSubmissionCommentCreateKey,
  ...(mutationKey ?? []),
];
export type SubmissionTagCreateMutationResult = Awaited<ReturnType<typeof submissionTagCreate>>;
export const useSubmissionTagCreateKey = 'SubmissionTagCreate';
export const UseSubmissionTagCreateKeyFn = (mutationKey?: Array<unknown>) => [
  useSubmissionTagCreateKey,
  ...(mutationKey ?? []),
];
export type ScheduleAssetDiscoveryRunMutationResult = Awaited<
  ReturnType<typeof scheduleAssetDiscoveryRun>
>;
export const useScheduleAssetDiscoveryRunKey = 'ScheduleAssetDiscoveryRun';
export const UseScheduleAssetDiscoveryRunKeyFn = (mutationKey?: Array<unknown>) => [
  useScheduleAssetDiscoveryRunKey,
  ...(mutationKey ?? []),
];
export type AddDomainMutationResult = Awaited<ReturnType<typeof addDomain>>;
export const useAddDomainKey = 'AddDomain';
export const UseAddDomainKeyFn = (mutationKey?: Array<unknown>) => [
  useAddDomainKey,
  ...(mutationKey ?? []),
];
export type UserUpdateByIdMutationResult = Awaited<ReturnType<typeof userUpdateById>>;
export const useUserUpdateByIdKey = 'UserUpdateById';
export const UseUserUpdateByIdKeyFn = (mutationKey?: Array<unknown>) => [
  useUserUpdateByIdKey,
  ...(mutationKey ?? []),
];
export type UserUpdateMutationResult = Awaited<ReturnType<typeof userUpdate>>;
export const useUserUpdateKey = 'UserUpdate';
export const UseUserUpdateKeyFn = (mutationKey?: Array<unknown>) => [
  useUserUpdateKey,
  ...(mutationKey ?? []),
];
export type CompanyUpdateMutationResult = Awaited<ReturnType<typeof companyUpdate>>;
export const useCompanyUpdateKey = 'CompanyUpdate';
export const UseCompanyUpdateKeyFn = (mutationKey?: Array<unknown>) => [
  useCompanyUpdateKey,
  ...(mutationKey ?? []),
];
export type CompanyArchiveMutationResult = Awaited<ReturnType<typeof companyArchive>>;
export const useCompanyArchiveKey = 'CompanyArchive';
export const UseCompanyArchiveKeyFn = (mutationKey?: Array<unknown>) => [
  useCompanyArchiveKey,
  ...(mutationKey ?? []),
];
export type ProjectUpdateMutationResult = Awaited<ReturnType<typeof projectUpdate>>;
export const useProjectUpdateKey = 'ProjectUpdate';
export const UseProjectUpdateKeyFn = (mutationKey?: Array<unknown>) => [
  useProjectUpdateKey,
  ...(mutationKey ?? []),
];
export type ProjectArchiveMutationResult = Awaited<ReturnType<typeof projectArchive>>;
export const useProjectArchiveKey = 'ProjectArchive';
export const UseProjectArchiveKeyFn = (mutationKey?: Array<unknown>) => [
  useProjectArchiveKey,
  ...(mutationKey ?? []),
];
export type RiskUpdateMutationResult = Awaited<ReturnType<typeof riskUpdate>>;
export const useRiskUpdateKey = 'RiskUpdate';
export const UseRiskUpdateKeyFn = (mutationKey?: Array<unknown>) => [
  useRiskUpdateKey,
  ...(mutationKey ?? []),
];
export type UnlockRequestApproveMutationResult = Awaited<ReturnType<typeof unlockRequestApprove>>;
export const useUnlockRequestApproveKey = 'UnlockRequestApprove';
export const UseUnlockRequestApproveKeyFn = (mutationKey?: Array<unknown>) => [
  useUnlockRequestApproveKey,
  ...(mutationKey ?? []),
];
export type UnlockRequestRejectMutationResult = Awaited<ReturnType<typeof unlockRequestReject>>;
export const useUnlockRequestRejectKey = 'UnlockRequestReject';
export const UseUnlockRequestRejectKeyFn = (mutationKey?: Array<unknown>) => [
  useUnlockRequestRejectKey,
  ...(mutationKey ?? []),
];
export type ContractUpdateMutationResult = Awaited<ReturnType<typeof contractUpdate>>;
export const useContractUpdateKey = 'ContractUpdate';
export const UseContractUpdateKeyFn = (mutationKey?: Array<unknown>) => [
  useContractUpdateKey,
  ...(mutationKey ?? []),
];
export type TagUpdateMutationResult = Awaited<ReturnType<typeof tagUpdate>>;
export const useTagUpdateKey = 'TagUpdate';
export const UseTagUpdateKeyFn = (mutationKey?: Array<unknown>) => [
  useTagUpdateKey,
  ...(mutationKey ?? []),
];
export type EmailTemplateUpdateMutationResult = Awaited<ReturnType<typeof emailTemplateUpdate>>;
export const useEmailTemplateUpdateKey = 'EmailTemplateUpdate';
export const UseEmailTemplateUpdateKeyFn = (mutationKey?: Array<unknown>) => [
  useEmailTemplateUpdateKey,
  ...(mutationKey ?? []),
];
export type SubmissionUpdateStatusMutationResult = Awaited<
  ReturnType<typeof submissionUpdateStatus>
>;
export const useSubmissionUpdateStatusKey = 'SubmissionUpdateStatus';
export const UseSubmissionUpdateStatusKeyFn = (mutationKey?: Array<unknown>) => [
  useSubmissionUpdateStatusKey,
  ...(mutationKey ?? []),
];
export type SubmissionUpdateMutationResult = Awaited<ReturnType<typeof submissionUpdate>>;
export const useSubmissionUpdateKey = 'SubmissionUpdate';
export const UseSubmissionUpdateKeyFn = (mutationKey?: Array<unknown>) => [
  useSubmissionUpdateKey,
  ...(mutationKey ?? []),
];
export type SubmissionCommentUpdateMutationResult = Awaited<
  ReturnType<typeof submissionCommentUpdate>
>;
export const useSubmissionCommentUpdateKey = 'SubmissionCommentUpdate';
export const UseSubmissionCommentUpdateKeyFn = (mutationKey?: Array<unknown>) => [
  useSubmissionCommentUpdateKey,
  ...(mutationKey ?? []),
];
export type UpdateAssetDiscoveryRunStatusMutationResult = Awaited<
  ReturnType<typeof updateAssetDiscoveryRunStatus>
>;
export const useUpdateAssetDiscoveryRunStatusKey = 'UpdateAssetDiscoveryRunStatus';
export const UseUpdateAssetDiscoveryRunStatusKeyFn = (mutationKey?: Array<unknown>) => [
  useUpdateAssetDiscoveryRunStatusKey,
  ...(mutationKey ?? []),
];
export type UpdateAssetDiscoveryRunAssetsMutationResult = Awaited<
  ReturnType<typeof updateAssetDiscoveryRunAssets>
>;
export const useUpdateAssetDiscoveryRunAssetsKey = 'UpdateAssetDiscoveryRunAssets';
export const UseUpdateAssetDiscoveryRunAssetsKeyFn = (mutationKey?: Array<unknown>) => [
  useUpdateAssetDiscoveryRunAssetsKey,
  ...(mutationKey ?? []),
];
export type UpdateAssetIpEnumerationMutationResult = Awaited<
  ReturnType<typeof updateAssetIpEnumeration>
>;
export const useUpdateAssetIpEnumerationKey = 'UpdateAssetIpEnumeration';
export const UseUpdateAssetIpEnumerationKeyFn = (mutationKey?: Array<unknown>) => [
  useUpdateAssetIpEnumerationKey,
  ...(mutationKey ?? []),
];
export type UpdateDomainMutationResult = Awaited<ReturnType<typeof updateDomain>>;
export const useUpdateDomainKey = 'UpdateDomain';
export const UseUpdateDomainKeyFn = (mutationKey?: Array<unknown>) => [
  useUpdateDomainKey,
  ...(mutationKey ?? []),
];
export type ProjectPartialUpdateMutationResult = Awaited<ReturnType<typeof projectPartialUpdate>>;
export const useProjectPartialUpdateKey = 'ProjectPartialUpdate';
export const UseProjectPartialUpdateKeyFn = (mutationKey?: Array<unknown>) => [
  useProjectPartialUpdateKey,
  ...(mutationKey ?? []),
];
export type RiskPartialUpdateMutationResult = Awaited<ReturnType<typeof riskPartialUpdate>>;
export const useRiskPartialUpdateKey = 'RiskPartialUpdate';
export const UseRiskPartialUpdateKeyFn = (mutationKey?: Array<unknown>) => [
  useRiskPartialUpdateKey,
  ...(mutationKey ?? []),
];
export type RiskCommentPartialUpdateMutationResult = Awaited<
  ReturnType<typeof riskCommentPartialUpdate>
>;
export const useRiskCommentPartialUpdateKey = 'RiskCommentPartialUpdate';
export const UseRiskCommentPartialUpdateKeyFn = (mutationKey?: Array<unknown>) => [
  useRiskCommentPartialUpdateKey,
  ...(mutationKey ?? []),
];
export type CompanyInvitationDeleteMutationResult = Awaited<
  ReturnType<typeof companyInvitationDelete>
>;
export const useCompanyInvitationDeleteKey = 'CompanyInvitationDelete';
export const UseCompanyInvitationDeleteKeyFn = (mutationKey?: Array<unknown>) => [
  useCompanyInvitationDeleteKey,
  ...(mutationKey ?? []),
];
export type CompanyDeleteUserMutationResult = Awaited<ReturnType<typeof companyDeleteUser>>;
export const useCompanyDeleteUserKey = 'CompanyDeleteUser';
export const UseCompanyDeleteUserKeyFn = (mutationKey?: Array<unknown>) => [
  useCompanyDeleteUserKey,
  ...(mutationKey ?? []),
];
export type RiskCommentDeleteMutationResult = Awaited<ReturnType<typeof riskCommentDelete>>;
export const useRiskCommentDeleteKey = 'RiskCommentDelete';
export const UseRiskCommentDeleteKeyFn = (mutationKey?: Array<unknown>) => [
  useRiskCommentDeleteKey,
  ...(mutationKey ?? []),
];
export type TagDeleteMutationResult = Awaited<ReturnType<typeof tagDelete>>;
export const useTagDeleteKey = 'TagDelete';
export const UseTagDeleteKeyFn = (mutationKey?: Array<unknown>) => [
  useTagDeleteKey,
  ...(mutationKey ?? []),
];
export type RiskTagDeleteMutationResult = Awaited<ReturnType<typeof riskTagDelete>>;
export const useRiskTagDeleteKey = 'RiskTagDelete';
export const UseRiskTagDeleteKeyFn = (mutationKey?: Array<unknown>) => [
  useRiskTagDeleteKey,
  ...(mutationKey ?? []),
];
export type SubmissionDeleteMutationResult = Awaited<ReturnType<typeof submissionDelete>>;
export const useSubmissionDeleteKey = 'SubmissionDelete';
export const UseSubmissionDeleteKeyFn = (mutationKey?: Array<unknown>) => [
  useSubmissionDeleteKey,
  ...(mutationKey ?? []),
];
export type SubmissionRiskLinkDeleteMutationResult = Awaited<
  ReturnType<typeof submissionRiskLinkDelete>
>;
export const useSubmissionRiskLinkDeleteKey = 'SubmissionRiskLinkDelete';
export const UseSubmissionRiskLinkDeleteKeyFn = (mutationKey?: Array<unknown>) => [
  useSubmissionRiskLinkDeleteKey,
  ...(mutationKey ?? []),
];
export type SubmissionRiskLinkUnlinkMutationResult = Awaited<
  ReturnType<typeof submissionRiskLinkUnlink>
>;
export const useSubmissionRiskLinkUnlinkKey = 'SubmissionRiskLinkUnlink';
export const UseSubmissionRiskLinkUnlinkKeyFn = (mutationKey?: Array<unknown>) => [
  useSubmissionRiskLinkUnlinkKey,
  ...(mutationKey ?? []),
];
export type SubmissionCommentDeleteMutationResult = Awaited<
  ReturnType<typeof submissionCommentDelete>
>;
export const useSubmissionCommentDeleteKey = 'SubmissionCommentDelete';
export const UseSubmissionCommentDeleteKeyFn = (mutationKey?: Array<unknown>) => [
  useSubmissionCommentDeleteKey,
  ...(mutationKey ?? []),
];
export type SubmissionTagDeleteMutationResult = Awaited<ReturnType<typeof submissionTagDelete>>;
export const useSubmissionTagDeleteKey = 'SubmissionTagDelete';
export const UseSubmissionTagDeleteKeyFn = (mutationKey?: Array<unknown>) => [
  useSubmissionTagDeleteKey,
  ...(mutationKey ?? []),
];
export type DeleteDomainAssetMutationResult = Awaited<ReturnType<typeof deleteDomainAsset>>;
export const useDeleteDomainAssetKey = 'DeleteDomainAsset';
export const UseDeleteDomainAssetKeyFn = (mutationKey?: Array<unknown>) => [
  useDeleteDomainAssetKey,
  ...(mutationKey ?? []),
];
export type DeleteDomainMutationResult = Awaited<ReturnType<typeof deleteDomain>>;
export const useDeleteDomainKey = 'DeleteDomain';
export const UseDeleteDomainKeyFn = (mutationKey?: Array<unknown>) => [
  useDeleteDomainKey,
  ...(mutationKey ?? []),
];
