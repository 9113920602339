// generated with @7nohe/openapi-react-query-codegen@2.0.0-beta.3

import { type Options } from '@hey-api/client-axios';
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  adminCompanyList,
  adminContractList,
  adminProjectList,
  adminRiskList,
  adminUserList,
  assetDiscoveryRunList,
  commentList,
  emailTemplateList,
  riskExport,
  riskList,
  submissionCommentList,
  submissionExport,
  submissionList,
  unlockRequestList,
} from '../requests/services.gen';
import {
  AdminCompanyListData,
  AdminCompanyListError,
  AdminContractListData,
  AdminContractListError,
  AdminProjectListData,
  AdminProjectListError,
  AdminRiskListData,
  AdminRiskListError,
  AdminUserListData,
  AdminUserListError,
  AssetDiscoveryRunListData,
  AssetDiscoveryRunListError,
  CommentListData,
  CommentListError,
  EmailTemplateListData,
  EmailTemplateListError,
  RiskExportData,
  RiskExportError,
  RiskListData,
  RiskListError,
  SubmissionCommentListData,
  SubmissionCommentListError,
  SubmissionExportData,
  SubmissionExportError,
  SubmissionListData,
  SubmissionListError,
  UnlockRequestListData,
  UnlockRequestListError,
} from '../requests/types.gen';
import * as Common from './common';
export const useAdminUserListInfinite = <
  TData = InfiniteData<Common.AdminUserListDefaultResponse>,
  TError = AxiosError<AdminUserListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<AdminUserListData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseInfiniteQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useInfiniteQuery({
    queryKey: Common.UseAdminUserListKeyFn(clientOptions, queryKey),
    queryFn: ({ pageParam }) =>
      adminUserList({
        ...clientOptions,
        query: { ...clientOptions.query, page: pageParam as number },
      }).then((response) => response.data as TData) as TData,
    initialPageParam: '1',
    getNextPageParam: (response) =>
      (
        response as {
          nextPage: number;
        }
      ).nextPage,
    ...options,
  });
export const useAdminCompanyListInfinite = <
  TData = InfiniteData<Common.AdminCompanyListDefaultResponse>,
  TError = AxiosError<AdminCompanyListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<AdminCompanyListData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseInfiniteQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useInfiniteQuery({
    queryKey: Common.UseAdminCompanyListKeyFn(clientOptions, queryKey),
    queryFn: ({ pageParam }) =>
      adminCompanyList({
        ...clientOptions,
        query: { ...clientOptions.query, page: pageParam as number },
      }).then((response) => response.data as TData) as TData,
    initialPageParam: '1',
    getNextPageParam: (response) =>
      (
        response as {
          nextPage: number;
        }
      ).nextPage,
    ...options,
  });
export const useAdminProjectListInfinite = <
  TData = InfiniteData<Common.AdminProjectListDefaultResponse>,
  TError = AxiosError<AdminProjectListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<AdminProjectListData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseInfiniteQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useInfiniteQuery({
    queryKey: Common.UseAdminProjectListKeyFn(clientOptions, queryKey),
    queryFn: ({ pageParam }) =>
      adminProjectList({
        ...clientOptions,
        query: { ...clientOptions.query, page: pageParam as number },
      }).then((response) => response.data as TData) as TData,
    initialPageParam: '1',
    getNextPageParam: (response) =>
      (
        response as {
          nextPage: number;
        }
      ).nextPage,
    ...options,
  });
export const useRiskListInfinite = <
  TData = InfiniteData<Common.RiskListDefaultResponse>,
  TError = AxiosError<RiskListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<RiskListData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseInfiniteQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useInfiniteQuery({
    queryKey: Common.UseRiskListKeyFn(clientOptions, queryKey),
    queryFn: ({ pageParam }) =>
      riskList({
        ...clientOptions,
        query: { ...clientOptions.query, page: pageParam as number },
      }).then((response) => response.data as TData) as TData,
    initialPageParam: '1',
    getNextPageParam: (response) =>
      (
        response as {
          nextPage: number;
        }
      ).nextPage,
    ...options,
  });
export const useAdminRiskListInfinite = <
  TData = InfiniteData<Common.AdminRiskListDefaultResponse>,
  TError = AxiosError<AdminRiskListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<AdminRiskListData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseInfiniteQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useInfiniteQuery({
    queryKey: Common.UseAdminRiskListKeyFn(clientOptions, queryKey),
    queryFn: ({ pageParam }) =>
      adminRiskList({
        ...clientOptions,
        query: { ...clientOptions.query, page: pageParam as number },
      }).then((response) => response.data as TData) as TData,
    initialPageParam: '1',
    getNextPageParam: (response) =>
      (
        response as {
          nextPage: number;
        }
      ).nextPage,
    ...options,
  });
export const useRiskExportInfinite = <
  TData = InfiniteData<Common.RiskExportDefaultResponse>,
  TError = AxiosError<RiskExportError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<RiskExportData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseInfiniteQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useInfiniteQuery({
    queryKey: Common.UseRiskExportKeyFn(clientOptions, queryKey),
    queryFn: ({ pageParam }) =>
      riskExport({
        ...clientOptions,
        query: { ...clientOptions.query, page: pageParam as number },
      }).then((response) => response.data as TData) as TData,
    initialPageParam: '1',
    getNextPageParam: (response) =>
      (
        response as {
          nextPage: number;
        }
      ).nextPage,
    ...options,
  });
export const useUnlockRequestListInfinite = <
  TData = InfiniteData<Common.UnlockRequestListDefaultResponse>,
  TError = AxiosError<UnlockRequestListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<UnlockRequestListData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseInfiniteQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useInfiniteQuery({
    queryKey: Common.UseUnlockRequestListKeyFn(clientOptions, queryKey),
    queryFn: ({ pageParam }) =>
      unlockRequestList({
        ...clientOptions,
        query: { ...clientOptions.query, page: pageParam as number },
      }).then((response) => response.data as TData) as TData,
    initialPageParam: '1',
    getNextPageParam: (response) =>
      (
        response as {
          nextPage: number;
        }
      ).nextPage,
    ...options,
  });
export const useCommentListInfinite = <
  TData = InfiniteData<Common.CommentListDefaultResponse>,
  TError = AxiosError<CommentListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<CommentListData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseInfiniteQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useInfiniteQuery({
    queryKey: Common.UseCommentListKeyFn(clientOptions, queryKey),
    queryFn: ({ pageParam }) =>
      commentList({
        ...clientOptions,
        query: { ...clientOptions.query, page: pageParam as number },
      }).then((response) => response.data as TData) as TData,
    initialPageParam: '1',
    getNextPageParam: (response) =>
      (
        response as {
          nextPage: number;
        }
      ).nextPage,
    ...options,
  });
export const useAdminContractListInfinite = <
  TData = InfiniteData<Common.AdminContractListDefaultResponse>,
  TError = AxiosError<AdminContractListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<AdminContractListData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseInfiniteQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useInfiniteQuery({
    queryKey: Common.UseAdminContractListKeyFn(clientOptions, queryKey),
    queryFn: ({ pageParam }) =>
      adminContractList({
        ...clientOptions,
        query: { ...clientOptions.query, page: pageParam as number },
      }).then((response) => response.data as TData) as TData,
    initialPageParam: '1',
    getNextPageParam: (response) =>
      (
        response as {
          nextPage: number;
        }
      ).nextPage,
    ...options,
  });
export const useEmailTemplateListInfinite = <
  TData = InfiniteData<Common.EmailTemplateListDefaultResponse>,
  TError = AxiosError<EmailTemplateListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<EmailTemplateListData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseInfiniteQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useInfiniteQuery({
    queryKey: Common.UseEmailTemplateListKeyFn(clientOptions, queryKey),
    queryFn: ({ pageParam }) =>
      emailTemplateList({
        ...clientOptions,
        query: { ...clientOptions.query, page: pageParam as number },
      }).then((response) => response.data as TData) as TData,
    initialPageParam: '1',
    getNextPageParam: (response) =>
      (
        response as {
          nextPage: number;
        }
      ).nextPage,
    ...options,
  });
export const useSubmissionListInfinite = <
  TData = InfiniteData<Common.SubmissionListDefaultResponse>,
  TError = AxiosError<SubmissionListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<SubmissionListData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseInfiniteQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useInfiniteQuery({
    queryKey: Common.UseSubmissionListKeyFn(clientOptions, queryKey),
    queryFn: ({ pageParam }) =>
      submissionList({
        ...clientOptions,
        query: { ...clientOptions.query, page: pageParam as number },
      }).then((response) => response.data as TData) as TData,
    initialPageParam: '1',
    getNextPageParam: (response) =>
      (
        response as {
          nextPage: number;
        }
      ).nextPage,
    ...options,
  });
export const useSubmissionExportInfinite = <
  TData = InfiniteData<Common.SubmissionExportDefaultResponse>,
  TError = AxiosError<SubmissionExportError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<SubmissionExportData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseInfiniteQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useInfiniteQuery({
    queryKey: Common.UseSubmissionExportKeyFn(clientOptions, queryKey),
    queryFn: ({ pageParam }) =>
      submissionExport({
        ...clientOptions,
        query: { ...clientOptions.query, page: pageParam as number },
      }).then((response) => response.data as TData) as TData,
    initialPageParam: '1',
    getNextPageParam: (response) =>
      (
        response as {
          nextPage: number;
        }
      ).nextPage,
    ...options,
  });
export const useSubmissionCommentListInfinite = <
  TData = InfiniteData<Common.SubmissionCommentListDefaultResponse>,
  TError = AxiosError<SubmissionCommentListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<SubmissionCommentListData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseInfiniteQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useInfiniteQuery({
    queryKey: Common.UseSubmissionCommentListKeyFn(clientOptions, queryKey),
    queryFn: ({ pageParam }) =>
      submissionCommentList({
        ...clientOptions,
        query: { ...clientOptions.query, page: pageParam as number },
      }).then((response) => response.data as TData) as TData,
    initialPageParam: '1',
    getNextPageParam: (response) =>
      (
        response as {
          nextPage: number;
        }
      ).nextPage,
    ...options,
  });
export const useAssetDiscoveryRunListInfinite = <
  TData = InfiniteData<Common.AssetDiscoveryRunListDefaultResponse>,
  TError = AxiosError<AssetDiscoveryRunListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<AssetDiscoveryRunListData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseInfiniteQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useInfiniteQuery({
    queryKey: Common.UseAssetDiscoveryRunListKeyFn(clientOptions, queryKey),
    queryFn: ({ pageParam }) =>
      assetDiscoveryRunList({
        ...clientOptions,
        query: { ...clientOptions.query, page: pageParam as number },
      }).then((response) => response.data as TData) as TData,
    initialPageParam: '1',
    getNextPageParam: (response) =>
      (
        response as {
          nextPage: number;
        }
      ).nextPage,
    ...options,
  });
