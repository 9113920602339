import { Badge, BadgeProps } from '@mantine/core';

import classes from './RiskStatusBadge.module.css';

import { RiskStatusEnum, SubmissionStatusEnum } from '@/openapi/requests';

const riskStatusOptions: Record<RiskStatusEnum | SubmissionStatusEnum, string> = {
  DRAFT: 'Draft',
  REVIEW: 'Review',
  NEW: 'New',
  IN_PROGRESS: 'In Progress',
  CLOSED: 'Closed',
  REJECTED: 'Rejected',
  DUPLICATE: 'Duplicate',
  ACCEPTED: 'Accepted',
};

interface RiskStatusBadgeProps {
  status: RiskStatusEnum | SubmissionStatusEnum;
  size?: BadgeProps['size'];
  isRisk?: boolean;
}

export const RiskStatusBadge = ({ status, size, isRisk }: RiskStatusBadgeProps) => (
  <Badge radius="sm" className={classes[status.toLowerCase()]} size={size}>
    {isRisk && status === RiskStatusEnum.ACCEPTED ? 'Risk Accepted' : riskStatusOptions[status]}
  </Badge>
);
