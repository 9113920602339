import { Box, Button, Divider, Group, Tabs } from '@mantine/core';
import { useEffect, useState } from 'react';
import {
  IconDownload,
  IconFilter,
  IconLayoutList,
  IconSortAscending,
  IconSortDescending,
} from '@tabler/icons-react';
import { ListLayout } from './ListLayout';
import { ProjectDto } from '@/openapi/requests';
import { SubmissionFilters } from '../SubmissionFilters/SubmissionFilters';
import { useLayout } from '@/components/Risks/RiskList/utils';
import classes from '@/components/Risks/RiskList/RiskList.module.css';
import { useSubmissionSortingStore } from '@/store/submission-sorting-store';
import { useSubmissionExport } from '@/openapi/queries';
import { notifications } from '@mantine/notifications';
import { handleFileDownload } from '@/lib/helpers';
import { useSubmissionFiltersStore } from '@/store/submission-store';

interface SubmissionListProps {
  project: ProjectDto;
}

const SubmissionList = ({ project }: SubmissionListProps) => {
  const { categories, severities, status: statuses, tags, dateRange } = useSubmissionFiltersStore();
  const { order, setOrder } = useSubmissionSortingStore();
  const layout = useLayout();
  const [showFilter, setShowFilter] = useState(false);
  const { refetch, status, data } = useSubmissionExport(
    {
      query: {
        projectId: project.id,
        categories,
        severities,
        statuses,
        tags,
        dateRange,
      },
      path: {
        projectId: project.id,
      },
    },
    undefined,
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (status === 'error') {
      notifications.show({
        title: 'Export Failed',
        message: 'Failed to export submissions',
        color: 'red',
      });
      return;
    }
    if (status === 'success' && data) {
      handleFileDownload(data.signedLink, data.fileName);
    }
  }, [status, data]);

  const filterStyle = {
    overflow: 'hidden',
    maxHeight: showFilter ? '50px' : '0',
    transition: 'max-height 0.5s ease-in-out',
  };

  const toggleFilter = () => setShowFilter(!showFilter);

  return (
    <Tabs
      defaultValue={layout}
      classNames={{
        list: classes['mantine-Tabs-list'],
      }}
    >
      <Tabs.List
        styles={{
          list: {
            border: 0,
          },
        }}
        justify="flex-end"
        className={classes.tabList}
      >
        <Group>
          <Tabs.Tab
            key="list"
            value="list"
            leftSection={<IconLayoutList stroke={1.5} size="1rem" />}
          >
            List
          </Tabs.Tab>
        </Group>
        <Group mb={3} gap={2}>
          <Button
            onClick={toggleFilter}
            leftSection={<IconFilter stroke={1.5} size="1rem" />}
            variant={showFilter ? 'light' : 'subtle'}
          >
            {showFilter ? 'Hide' : 'Filter'}
          </Button>
          <Button
            onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
            leftSection={
              order === 'asc' ? (
                <IconSortAscending stroke={1.5} size="1rem" />
              ) : (
                <IconSortDescending stroke={1.5} size="1rem" />
              )
            }
            variant="subtle"
          >
            Sort
          </Button>
          <Button
            onClick={() => refetch()}
            leftSection={<IconDownload stroke={1.5} size="1rem" />}
            variant="subtle"
          >
            Export
          </Button>
        </Group>
      </Tabs.List>
      <Box style={filterStyle}>
        <SubmissionFilters activeTab={layout} />
        <Divider />
      </Box>
      <Tabs.Panel value="list">
        <ListLayout project={project} />
      </Tabs.Panel>
    </Tabs>
  );
};

export default SubmissionList;
