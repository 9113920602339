import { AssetDiscoveryRunStatusEnum } from '@/openapi/requests';
import { Badge } from '@mantine/core';
import classes from './AssetStatusBadge.module.css';

const assetStatusOptions = {
  PENDING: 'Pending',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  FAILED: 'Failed',
} as const;

const AssetStatusBadge = ({ status }: { status: AssetDiscoveryRunStatusEnum }) => {
  return (
    <Badge radius="sm" className={classes[status.toLowerCase()]} size="sm">
      {assetStatusOptions[status]}
    </Badge>
  );
};

export default AssetStatusBadge;
