import { create } from 'zustand';

interface UseSubmissionSorting {
  order: 'asc' | 'desc';
  setOrder: (order: 'asc' | 'desc') => void;
}

export const useSubmissionSortingStore = create<UseSubmissionSorting>()((set) => ({
  order: 'desc',
  setOrder: (order) => set({ order }),
}));
