import { Fragment, useEffect } from 'react';
import LoadingScreen from '@/components/Common/LoadingScreen/LoadingScreen';
import { ProjectDto, SubmissionListDto } from '@/openapi/requests';
import { SubmissionCard } from '../SubmissionCard/SubmissionCard';
import { useSubmissionFiltersStore } from '@/store/submission-store';
import { useSubmissionSortingStore } from '@/store/submission-sorting-store';
import { useSubmissionListInfinite } from '@/openapi/queries/infiniteQueries';
import { Loader } from '@mantine/core';
import { Center } from '@mantine/core';
import { useInView } from 'react-intersection-observer';

interface SubmissionCardsProps {
  project: ProjectDto;
}

const SubmissionCards = ({ project }: SubmissionCardsProps) => {
  const { ref, inView } = useInView({
    rootMargin: '200px 0px',
    threshold: 0,
  });
  const { categories, severities, status, tags, dateRange } = useSubmissionFiltersStore();
  const { order } = useSubmissionSortingStore();
  const { data, isLoading, isError, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useSubmissionListInfinite(
      {
        query: {
          projectId: project.id,
          categories,
          severities,
          statuses: status,
          tags,
          dateRange,
          sortBy: 'createdAt',
          sortOrder: order,
        },
      },
      undefined,
      {
        initialPageParam: 1,
        getNextPageParam: (data) => {
          const pageData = data as unknown as SubmissionListDto;
          return pageData.nextPage;
        },
        retry: false,
      }
    );

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView, hasNextPage]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    return <div>Error!</div>;
  }

  return data?.pages.map((page) => (
    <Fragment key={page?.page}>
      {page?.results.map((submission) => (
        <SubmissionCard submission={submission} key={submission.id} projectId={project.id} />
      ))}
      <div ref={ref} />
      {isFetchingNextPage && (
        <Center>
          <Loader color="blue" type="dots" />
        </Center>
      )}
    </Fragment>
  ));
};

export default SubmissionCards;
