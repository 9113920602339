import { useEffect, useState } from 'react';
import { Box, Button, Divider, Group, Tabs } from '@mantine/core';
import {
  IconDownload,
  IconFilter,
  IconLayoutKanban,
  IconLayoutList,
  IconPlus,
  IconSortAscending,
  IconSortDescending,
} from '@tabler/icons-react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { ContractListDto, ProjectDto, UserDto, UserTypeEnum } from '@/openapi/requests';
import { KanbanLayout } from './KanbanLayout';
import { ListLayout } from './ListLayout';
import { useLayout } from './utils';

import classes from './RiskList.module.css';
import { TabActions } from './TabActions';
import { useUserType } from '@/hooks/use-user-type';
import { paths } from '@/configuration';
import If from '@/components/Common/If/If';
import { useRiskSortingStore } from '@/store/risk-sorting-store';
import { useRiskExport } from '@/openapi/queries/queries';
import { notifications } from '@mantine/notifications';
import { handleFileDownload } from '@/lib/helpers';
import { useRiskFilters } from '@/store/risk-store';

interface RiskListProps {
  project: ProjectDto;
}
interface LoaderProps {
  contractList: ContractListDto;
  user: UserDto;
}

type TabValue = 'list' | 'kanban';

const RiskList = ({ project }: RiskListProps) => {
  const { categories, severities, status: statuses, tags, dateRange } = useRiskFilters();
  const { order, setOrder } = useRiskSortingStore();
  const layout = useLayout();
  const [activeTab] = useState<TabValue>(layout as TabValue);
  const [showFilter, setShowFilter] = useState(false);
  const usertype = useUserType();
  const navigate = useNavigate();
  const { contractList } = useLoaderData() as LoaderProps;
  const contract = contractList.results[0];
  const { refetch, status, data } = useRiskExport(
    {
      query: {
        project: project.id,
        categories,
        severities,
        statuses,
        tags,
        dateRange,
      },
      path: {
        projectId: project.id,
      },
    },
    undefined,
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (status === 'error') {
      notifications.show({
        title: 'Export Failed',
        message: 'Failed to export risks',
        color: 'red',
      });
      return;
    }
    if (status === 'success' && data) {
      handleFileDownload(data.signedLink, data.fileName);
    }
  }, [status, data]);

  const filterStyle = {
    overflow: 'hidden',
    maxHeight: showFilter ? '50px' : '0',
    transition: 'max-height 0.5s ease-in-out',
  };

  const toggleFilter = () => setShowFilter(!showFilter);

  return (
    <>
      <Tabs
        defaultValue={layout}
        classNames={{
          list: classes['mantine-Tabs-list'],
        }}
      >
        <Tabs.List
          styles={{
            list: {
              border: 0,
            },
          }}
          justify="flex-end"
          className={classes.tabList}
        >
          <Group>
            <Tabs.Tab
              key="list"
              value="list"
              leftSection={<IconLayoutList stroke={1.5} size="1rem" />}
            >
              List
            </Tabs.Tab>
            <Tabs.Tab
              key="kanban"
              value="kanban"
              leftSection={<IconLayoutKanban stroke={1.5} size="1rem" />}
            >
              Kanban
            </Tabs.Tab>
          </Group>

          <Group align="center" mb={3} gap={2}>
            <Button
              onClick={toggleFilter}
              leftSection={<IconFilter stroke={1.5} size="1rem" />}
              variant={showFilter ? 'light' : 'subtle'}
            >
              {showFilter ? 'Hide' : 'Filter'}
            </Button>
            <Button
              onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
              leftSection={
                order === 'asc' ? (
                  <IconSortAscending stroke={1.5} size="1rem" />
                ) : (
                  <IconSortDescending stroke={1.5} size="1rem" />
                )
              }
              variant="subtle"
            >
              Sort
            </Button>
            <If condition={usertype === UserTypeEnum.RESEARCHER}>
              <Button
                mb={5}
                leftSection={<IconPlus stroke={1.5} size="1rem" />}
                variant=""
                onClick={() => {
                  navigate(`${paths.newRisk(project.id)}?contract=${contract.id}`);
                }}
              >
                Add Risk
              </Button>
            </If>
            <Button
              onClick={() => refetch()}
              leftSection={<IconDownload stroke={1.5} size="1rem" />}
              variant="subtle"
            >
              Export
            </Button>
          </Group>
        </Tabs.List>
        <Box style={filterStyle}>
          <TabActions activeTab={activeTab} />
          <Divider />
        </Box>
        <Tabs.Panel value="list">
          <ListLayout project={project} />
        </Tabs.Panel>
        <Tabs.Panel value="kanban">
          <KanbanLayout project={project} />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export default RiskList;
