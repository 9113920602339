import { useAddDomain } from '@/openapi/queries/queries';
import { Group, Stack, TextInput } from '@mantine/core';
import { Button } from '@mantine/core';
import { Modal } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useEffect } from 'react';
import { notifications } from '@mantine/notifications';
import { z } from 'zod';
import { useQueryClient } from '@tanstack/react-query';
import { useDomainListKey } from '@/openapi/queries';

interface AddDomainModalProps {
  opened: boolean;
  onClose: () => void;
  companyId: string | null;
}

const runDiscoverySchema = z.object({
  domain: z
    .string()
    .min(3, { message: 'Domain must be at least 3 characters' })
    .max(255, { message: 'Domain is too long' })
    .regex(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](\.[a-zA-Z]{2,})+$/, {
      message: 'Invalid domain format',
    }),
});

const AddDomainModal = ({ opened, onClose, companyId }: AddDomainModalProps) => {
  const queryClient = useQueryClient();
  const mutation = useAddDomain();
  const form = useForm({
    initialValues: {
      domain: '',
    },
    validate: zodResolver(runDiscoverySchema),
  });

  useEffect(() => {
    if (mutation.isSuccess) {
      notifications.show({
        color: 'green',
        title: 'Domain added',
        message: 'The domain has been added successfully',
      });
      queryClient.invalidateQueries({ queryKey: [useDomainListKey] });
      onClose();
    }
  }, [mutation.isSuccess]);

  const handleSubmit = ({ domain }: { domain: string }) => {
    if (!companyId || !domain) return;
    mutation.mutate({
      body: {
        domain,
      },
      path: {
        companyId: companyId,
      },
    });
  };

  return (
    <Modal opened={opened} onClose={onClose} title="Add Domain" size="md" centered>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack gap="md">
          <TextInput label="Domain" {...form.getInputProps('domain')} />
          <Group justify="end" mt="lg">
            <Button
              variant="default"
              onClick={() => {
                onClose();
                form.reset();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" loading={mutation.isPending} disabled={mutation.isPending}>
              Create
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};

export default AddDomainModal;
