import { create } from 'zustand';

interface UseRiskSorting {
  order: 'asc' | 'desc';
  setOrder: (order: 'asc' | 'desc') => void;
}

export const useRiskSortingStore = create<UseRiskSorting>()((set) => ({
  order: 'desc',
  setOrder: (order) => set({ order }),
}));
